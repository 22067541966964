import React, { useEffect, useState } from 'react';
import style from '../Style/subscription.module.css'
import Arrow from './icons/back Vector';
import { CSCCheckJWTExpired, CSCGetAccessToken } from './utills';
import Buttons from './button';
import axios from 'axios';
import Cross from "./icons/whiteCross.svg";
import { ReactComponent as Green } from './icons/green.svg';
import { Button, Modal } from 'antd';
import { antdMessageError, antdMessageSuccess } from '../../Components/common/antdMessageComponent';
import { checkSuccessCode, logOutHandlerForMobile, userProfileUpdateHandlerForMobile } from '../../utills/validator';
import { URLshortner } from "./utills";

const UpgradePlan = ({ setscreen, Data, rightvaluepassed, color, plan, clientInfo }) => {
    const queryParams = new URLSearchParams(window.location.search);
    const [loading, setLoading] = useState(false);
    const [data, setdata] = useState(Data);
    const [rightvalue, setrightvalue] = useState();
    const textColor = 'red';
    const backgroundColor = '#f5f5ff';
    const primaryTextColor = data?.userProfileCustomization?.primaryTextColor || '#00063d';
    const highlightColor = data?.userProfileCustomization?.highlightColor || '#7583ff';
    const inputfieldColor = data?.userProfileCustomization?.inputfieldColor || '#e0e4ff';
    const fontstyle = 'poppins';
    const [click, setclick] = useState(true);
    const [showBenefits, setShowBenefits] = useState(false);
    const [activeKey, setActiveKey] = useState(0);
    const [benefits, setBenefits] = useState();
    const [offers, setOffers] = useState();
    const [upgrade, setUpgrade] = useState();
    const [parsedURL, setParsedURL] = useState();
    const [paymentDomain,setPaymentDomain] = useState('https://payment-v2.conscent.art');

    const getSubscription = async () => {
        try {
          const response = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/authorization/list-user-subscriptions`,
            headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
          });
          setPaymentDomain(response?.data?.paymentDomain);
        } catch (error) {
          console.log(error.message);
          antdMessageError('Cannot get the payment Domain');
        }
      };
    
    useEffect(() => {
        const shortenURL = async () => {
            try {
                const shortenedURL1 = await URLshortner(`${paymentDomain}/checkout?subsId=${upgrade?.subscriptionId}&tierId=${upgrade?.tier?._id}`);
                const res = await shortenedURL1.text(); 
                setParsedURL(res);
            } catch (error) {
                console.error('Error shortening URL:', error);
            }
        };
        getSubscription()
        shortenURL();
    }, [upgrade]);
    
    useEffect(() => {
        const refreshToken = queryParams.get('refreshToken');
        refreshToken &&
            (async () => {
                const accessToken = localStorage.getItem('accessToken');
                CSCCheckJWTExpired(accessToken);
                if (accessToken.length > 10 && !CSCCheckJWTExpired(accessToken)) {
                    fetchData(accessToken);
                } else {
                    const accessToken = await CSCGetAccessToken(refreshToken);
                    localStorage.setItem('accessToken', accessToken);
                    fetchData(accessToken);
                }
            })();
    }, []);

    const fetchData = async (token) => {
        if (token) {
            try {
                const response = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_API_URL}/authorization/get-loggedin-user-details`,
                    headers: { Authorization: `Bearer ${token}` },
                });
                setdata(response.data);
                setLoading(false);
            } catch (error) {
                if (error?.response?.status === 404) {
                    antdMessageError('It seems your account has been logged out. Please try logging in again');
                    window.parent.postMessage({ source: 'logout-from-user' }, '*');
                    logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
                }
            }
        }
    };


    useEffect(() => {
        if (data?.userProfileCustomization?.drawerPosition === 'RIGHT') {
            setrightvalue(0);
        } else {
            setrightvalue();
        }
    }, [data, rightvalue]);
    useEffect(() => {
        if (upgrade !== undefined) {
            setclick(false)
        }
    }, [upgrade])

    return (
        <>
            {loading ? (
                <div
                    className={style['outercontainer']}
                    style={{
                        background: color,
                        right: rightvaluepassed,
                    }}
                >
                    <div className={style['loader-container']}>
                        <div className={style['loader']}></div>
                    </div>
                </div>
            ) : (
                <div
                    className={style['outercontainer']}
                    style={{
                        right: rightvalue,
                        background: data?.userProfileCustomization?.backgroundColor || backgroundColor,
                        fontFamily: data?.userProfileCustomization?.fontStyle || fontstyle,
                        color: data?.userProfileCustomization?.primaryTextColor || textColor,
                    }}
                >
                    <div className={style['editable']} >
                        <div
                            className={style['head']}
                            style={{ borderBottom: `.8px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}` }}
                        >
                            <div className={style['arrow']} onClick={() => setscreen('subscription')}>
                                <Arrow fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
                            </div>
                            <div className={style['topic']}>Upgrade Subscription</div>
                        </div>

                        <div className={style['content']}>
                            {plan?.map((item) => (
                                <div className={style['plans']}
                                    style={{ border: upgrade?.tier?._id === item?.tier?._id ? `1px solid ${data?.userProfileCustomization?.highlightColor}` : `1px solid ${primaryTextColor}`,
                                     boxShadow: upgrade?.tier?._id === item?.tier?._id ? `1px 1px 3px ${data?.userProfileCustomization?.highlightColor}` :'',
                                     color: upgrade?.tier?._id === item?.tier?._id ? highlightColor : primaryTextColor,
                                }}
                                    onClick={() => { setUpgrade(item) }}>
                                    <div className={style['plan_title']}>{item?.title}</div>
                                    <div className={style['plan_mid_part']}>
                                        <div className={style['plan_duration']}>
                                            <div> For {item?.tier?.duration}
                                                {item?.tier?.durationType === 'days' ? (item?.tier?.duration > 1 ? ' Days' : ' Day') : (item?.tier?.duration > 1 ? ' Months' : ' Month')}
                                            </div>
                                            {item?.tier?.basePrice > 0 && <div><span style={{ textDecoration: 'line-through', textAlign: 'center' }}>
                                                {item?.tier?.currency} {item?.tier?.basePrice}
                                            </span>
                                                &nbsp;&nbsp;Save {Math.round(item?.tier?.price / item?.tier?.basePrice * 100)}%</div>}
                                        </div>
                                        <div className={style['plan_price']}>
                                            {item?.tier?.currency} {item?.tier?.price}
                                        </div>
                                    </div>
                                    <div className={style['plan_benefits']} onClick={() => {
                                        setBenefits(item?.benefits)
                                        setOffers(item?.offers)
                                        setShowBenefits(true)
                                    }}>View Benefits & Offers</div>
                                </div>))}
                        </div>

                    </div>

                    <div className={style['buttoncontainer']}>
                        <Buttons
                            disable={click}
                            color={data?.userProfileCustomization?.highlightColor || highlightColor}
                            fnc={() => { window.open(`${window.location.origin}/login?anonId=${clientInfo?.anonId}&clientId=${clientInfo?.clientId}&redirectUrl=${parsedURL}`, '_blank', 'noopener,noreferrer') }}
                            text="Upgrade"
                            font={data?.userProfileCustomization?.fontStyle || fontstyle}
                        />
                    </div>

                    {showBenefits &&
                        <div style={{ background: '#000000ab', width: '100%', height: '100vh', position: 'absolute', top: '0', }}>
                            <div
                                style={{
                                    position: 'absolute',
                                    width: '98%',
                                    margin: '3px',
                                    top: '200px',
                                    background: '#ffffff',
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 10px rgba(18, 18, 18, .2)',
                                }}>
                                <div
                                    style={{ position: 'absolute', right: '0', top: '-25px', height: '25px', cursor: 'pointer' }}
                                    onClick={() => { setShowBenefits(false) }}>
                                    <img
                                        src={Cross}
                                        alt="Cross Button"
                                        className={style["none"]}
                                    />
                                </div>
                                <div className={style['tabs-container']}>
                                    {['Benefits', 'Offers'].map((item, i) => (
                                        <div key={i} onClick={() => setActiveKey(i)}>
                                            <div className={activeKey === i ? style['active-tabs'] : style['tabs']}>{item}</div>
                                            <div className={activeKey === i ? style['border-bottom'] : style['normal-border-bottom']}></div>
                                        </div>
                                    ))}
                                </div>
                                <div className={style['benefits']}>
                                    {activeKey === 0 && benefits?.map((item) => (<div style={{ display: 'flex', gap: '7px', alignItems: 'top' }}>
                                        <div style={{ paddingTop: '2px', height: 'max-content' }}><Green /></div>
                                        <div>{item}</div>
                                    </div>))}
                                    {activeKey === 1 && offers?.map((item) => (<div style={{ display: 'flex', gap: '7px', alignItems: 'top' }}>
                                        <div style={{ paddingTop: '2px', height: 'max-content' }}><Green /></div>
                                        <div>{item?.benefits}</div>
                                    </div>))}
                                </div>

                            </div>
                        </div>
                    }
                </div>

            )}


        </>
    );
};

export default UpgradePlan;




// https://conscent-payment-stage.netlify.app/checkout?clientId=${clientInfo?.clientId}&tierId=${upgrade?.tier?._id}&subsId=${upgrade?.subscriptionId}&anonId=${clientInfo?.anonId}
