import React, { useEffect, useRef, useState } from 'react';
import loginStyle from './review-screens.module.scss';
import style from '../Style/Login.module.css';
import Demo_image from '../assets/loginsettings/demo_image.svg';
import { handleAuthCodeGeneration } from './Utills';
import {
  googleLogInHandlerForMobile,
  loginHandlerForMobile,
  errorHandlerForMobile,
  appleLogInHandlerForMobile,
  slientLoginHandlerForMobile,
  facebookLogInHandlerForMobile,
} from '../../utills/validator';
import Mobile_icon from '../assets/loginsettings/mobile_icon.svg';
import Facebook_icon from '../assets/loginsettings/facebook_icon.svg';
import NewLoader from '../../utills/NewLoader';
import Email_icon from '../assets/loginsettings/email_icon.svg';
import Profile_icon from '../assets/loginsettings/profile_icon.svg';
import { Input, Segmented } from 'antd';
import Apple_icon from '../assets/loginsettings/apple_icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ValidateMobileNumber, checkSuccessCode, validateEmail } from '../../utills/validator';
import { useNavigate } from 'react-router-dom';
import { antdMessageError, antdMessageWarning } from '../common/antdMessageComponent';
// import { setRegisterDetails } from '../../Reducer/commonData';
import ReCAPTCHA from 'react-google-recaptcha';
import { CSCSendEvent, clickAction, eventLocation, eventType } from '../../analytics';
import { useGoogleLogin } from '@react-oauth/google';
import { setRegisterDetails } from '../../Reducer/commonData';
import FacebookLogin from '@greatsumini/react-facebook-login';
import AppleLogin from 'react-apple-login';
import { isValidEmail, isValidNumber, redirectToDomain, removeDuplicateParams } from '../user/utills';
import CTALoader from '../../utills/ctaLoader';
import { Footer } from './Footer';

export function NewLogin() {
  const appleLoginButtonRef = useRef(null);
  const facebookLoginButtonRef = useRef(null);
  const queryParams = new URLSearchParams(window.location.search);
  const isGoogleLoginFlow = queryParams.get('useGoogleLogin');
  const isAppleLoginFlow = queryParams.get('useAppleLogin');
  const isFacebookLoginFlow = queryParams.get('useFacebookLogin');
  const redirectUrl = queryParams.get('redirectUrl');
  const commanUrlValue = useSelector((state) => state.commanUrlValue);
  const recaptchaRef = useRef(null);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.newClientConfig.login);
  const websiteDomain = useSelector((state) => state.websiteDomain);
  const toggleData = useSelector((state) => state.loginSetting);
  const clientGroupId = useSelector((state) => state.clientGroupId);
  const userDetails = useSelector((state) => state.userDetails);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [recaptcha, setRecaptcha] = useState({
    recaptchaVersion: '',
    recaptchaToken: '',
  });
  const [loading, setLoading] = useState(true);
  const [ctaLoading, setCTALoading] = useState(false);
  const googleIdToken = queryParams.get('google_id_token');
  const loginMethods = toggleData?.loginMethods;
  const accessMethods = toggleData?.accessMethods;
  const userEmail = queryParams.get('email');
  const userPhone = queryParams.get('phone');
  const mobileView = queryParams.get('mobileView') || false;
  const inAppPurchase = queryParams.get('inAppPurchase') || '';
  const productId = queryParams.get('productId') || '';
  const subsId = queryParams.get('subsId') || queryParams.get('subId') || '';
  const tierId = queryParams.get('tierID') || queryParams.get('tierId') || '';
  const apple_id_token = queryParams.get('apple_id_token');
  const facebook_id_token = queryParams.get('facebook_id_token');
  const facebookId = queryParams.get('facebookId');
  const facebookMail = queryParams.get('facebookEmail');
  const getUserLoginDetailsCallCount = useRef(0);
  const [showAppleLogin, setShowAppleLogin] = useState(true);
  const loginFeatureType = queryParams.get('loginFeatureType');
  const [hideSocialLogin, setHideSocialLogin] = useState(false);
  useEffect(() => {
    if (loginFeatureType === 'noSocialLogin') {
      setHideSocialLogin(true);
    }
    const giftedEmail = queryParams.get('giftedEmail');
    // alert(typeof giftedEmail);
    if (giftedEmail === 'undefined' || giftedEmail === undefined || giftedEmail === null) {
      console.log('fine');
      // queryParams.delete('giftedEmail');
    } else {
      const tempUserEmail = localStorage.getItem('userEmail');
      if (giftedEmail != tempUserEmail) {
        localStorage.clear();
        // window.location.reload();
      }
    }
  }, []);
  const checkinput = (value) => {
    if (loginMethods?.enableEmailLogin && validateEmail(value.toLowerCase())) {
      setBtnDisabled(false);
      // dispatch(
      //   setRegisterDetails({
      //     ...userDetails,
      //     emailPhone: value,
      //     email: value.toLowerCase(),
      //     primary: 'email',
      //     loginType: 'EMAIL',
      //   }),
      // );
      return dispatch(
        setRegisterDetails({
          ...userDetails,
          emailPhone: value,
          email: value.toLowerCase(),
          loginType: 'EMAIL',
          primary: 'email',
        }),
      );
    }
    if (loginMethods?.enableMobileLogin && ValidateMobileNumber(value)) {
      setBtnDisabled(false);
      // dispatch(
      //   setRegisterDetails({ ...userDetails, emailPhone: value, phone: value, primary: 'phone', loginType: 'PHONE' }),
      // );
      return dispatch(
        setRegisterDetails({ ...userDetails, emailPhone: value, phone: value, loginType: 'PHONE', primary: 'phone' }),
      );
    }
    dispatch(
      setRegisterDetails({ ...userDetails, emailPhone: value, phone: '', email: '', primary: '', loginType: '' }),
    );
    dispatch(setRegisterDetails({ ...userDetails, emailPhone: value, phone: '', email: '', loginType: '' }));
    setBtnDisabled(true);
  };

  const isValid = (value) => value !== null && value !== undefined && value !== 'null' && value !== 'undefined' && value !== '';

  useEffect(() => {
    if (validateEmail(userDetails.email)) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      const isAndroid = /android/i.test(userAgent);
      const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
      const isDesktop = !isAndroid && !isIOS;

      if (isAndroid && mobileView) {
        setShowAppleLogin(false); // Hide for Android WebView
      } else {
        setShowAppleLogin(true); // Show for other platforms
      }
    }
  }, []);
  useEffect(() => {
    window.addEventListener('message', (message) => {
      if (message.origin === 'https://accounts.google.com') {
        console.log('recieved EventListen');
      }
    });
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const isAndroid = /android/i.test(userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
    const isDesktop = !isAndroid && !isIOS;

    if (isAndroid && mobileView) {
      setShowAppleLogin(false); // Hide for Android WebView
    } else {
      setShowAppleLogin(true); // Show for other platforms
    }

    if (isAndroid && mobileView) {
      setShowAppleLogin(false); // Hide for Android WebView
    } else {
      setShowAppleLogin(true); // Show for other platforms
    }

    if (validateEmail(userDetails.email)) {
      return setBtnDisabled(false);
    }
    if (ValidateMobileNumber(userDetails.phone)) {
      return setBtnDisabled(false);
    }
  }, []);
  // useEffect(() => {
  //   console.log(toggleData, 'sdkfijkhioerj');
  //   document.title = toggleData.documentTitle ? toggleData.documentTitle : 'SSO Login';
  //   let link = document.querySelector("link[rel~='icon']");
  //   if (!link) {
  //     link = document.createElement('link');
  //     link.rel = 'icon';
  //     document.getElementsByTagName('head')[0].appendChild(link);
  //   }
  //   link.href = toggleData.brandLogoUrl;
  // }, []);
  const clientId = queryParams.get('clientId');
  const purchaseType = queryParams.get('purchaseType');

  useEffect(() => {
    (async () => {
      const userCode = localStorage.getItem('code') || '';
      window.localStorage.setItem('redirectUrl', redirectUrl);
      console.log('testingssoone', userCode);

      if (userCode) {
        try {
          const codedata = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
            headers: { Authorization: `Bearer ${userCode}` },
          });
          console.log('testingssotwo', redirectUrl);

          if (redirectUrl) {
            if (redirectUrl === 'https://conscent-app-sdk') {
              try {
                const dataformobile = {
                  token: codedata.data.authCode,
                  status: true,
                  subsId: subsId,
                  tierID: tierId,
                  productId: productId,
                };
                loginHandlerForMobile(JSON.stringify(dataformobile));
              } catch (err) {
                console.log(err, 'error while sending data to mobile app');
              }
            } else {
              console.log('testingssothree');
              slientLoginHandlerForMobile(
                JSON.stringify({
                  message: 'SLIENT_USER_LOGIN',
                  authToken: codedata.data.authCode,
                  statusCode: '200',
                }),
              );
              // const fetchRedirectUrl = new URL(redirectUrl);
              // fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
              // const anonId = queryParams.get('anonId');
              // const paywallId = queryParams.get('paywallId');
              // fetchRedirectUrl.searchParams.set('anonId', anonId);
              // fetchRedirectUrl.searchParams.set('paywallId', paywallId);
              // fetchRedirectUrl.searchParams.set('clientId', clientId);
              // fetchRedirectUrl.searchParams.set('mobileView', mobileView);
              // if (inAppPurchase) {
              //   fetchRedirectUrl.searchParams.set('inAppPurchase', inAppPurchase);
              //   fetchRedirectUrl.searchParams.set('tierId', tierId);
              //   fetchRedirectUrl.searchParams.set('productId', productId);
              //   fetchRedirectUrl.searchParams.set('subsId', subsId);
              // }
              console.log(JSON.stringify(commanUrlValue), 'kfuhiushdfios');
              handleAuthCodeGeneration(
                codedata.data.authCode,
                redirectUrl,
                commanUrlValue,
                commanUrlValue.clientId,
                commanUrlValue.SHOWPOP,
                commanUrlValue.popUpId,
                commanUrlValue.siteUrl,
                commanUrlValue.skip,
              );
              // window.location.href = removeDuplicateParams(fetchRedirectUrl.href);
              // }
            }
          } else {
            antdMessageError('A redirect URL is required but not provided. Please check and try again.');
          }
        } catch (err) {
          try {
            // Clear localStorage
            localStorage.clear();
            // Clear all cookies
            document.cookie.split(';').forEach((cookie) => {
              const name = cookie.split('=')[0].trim();
              document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            });
          } catch (error) {
            console.log(error);
          }
          const value = JSON.stringify({ message: 'SSO code expired', errorCode: '400' });

          errorHandlerForMobile(value);
          window.location.reload();
          console.log(err, 'error on generating auth code');
        }
      } else {
        try {
          localStorage.clear();
          document.cookie.split(';').forEach((cookie) => {
            const name = cookie.split('=')[0].trim();
            document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          });
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (clientGroupId) {
      if (googleIdToken) {
        try {
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/sso/google-one-tap-login`,
            data: {
              token: googleIdToken,
              clientGroupId: clientGroupId,
              clientId,
              deviceType: queryParams.get('deviceCategory'),
              signUpOriginUrl: redirectUrl,
              // paywallId: commanUrlValue.paywallId,
              // paywallType: commanUrlValue.paywallType,
              // clientContentId:commanUrlValue.clientContentId,
              // anonId:commanUrlValue.anonId,
              ...(isValid(commanUrlValue.clientContentId) && { clientContentId: commanUrlValue.clientContentId }),
              ...(isValid(commanUrlValue.paywallId) && { paywallId: commanUrlValue.paywallId }),
              ...(isValid(commanUrlValue.paywallType) && { paywallType: commanUrlValue.paywallType }),
              ...(isValid(commanUrlValue.anonId) && { anonId: commanUrlValue.anonId }),
            },
          })
            .then((googleLoginResponse) => {
              if (redirectUrl === 'https://conscent-app-sdk') {
                // const dataformobile = {
                //   token: googleLoginResponse.data.authorizationCode,
                //   status: true,
                // };
                localStorage.setItem('code', googleLoginResponse.data.authorizationCode);
                axios({
                  method: 'POST',
                  url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
                  headers: { Authorization: `Bearer ${googleLoginResponse.data.authorizationCode}` },
                }).then((res) => {
                  const dataformobile = {
                    token: res.data.authCode,
                    status: true,
                    subsId: subsId,
                    tierID: tierId,
                    productId: productId,
                  };
                  setLoading(false);
                  loginHandlerForMobile(JSON.stringify(dataformobile));
                });
              } else {
                const fetchRedirectUrl = new URL(redirectUrl);
                localStorage.setItem('code', googleLoginResponse.data.authorizationCode);
                axios({
                  method: 'POST',
                  url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
                  headers: { Authorization: `Bearer ${googleLoginResponse.data.authorizationCode}` },
                }).then((res) => {
                  slientLoginHandlerForMobile(
                    JSON.stringify({
                      message: 'SLIENT_USER_LOGIN',
                      authToken: localStorage.getItem('code'),
                      statusCode: '200',
                    }),
                  );
                  fetchRedirectUrl.searchParams.set('authorizationCode', res.data.authCode);
                  fetchRedirectUrl.searchParams.set('clientId', clientId);
                  fetchRedirectUrl.searchParams.set('mobileView', mobileView);
                  if (inAppPurchase) {
                    fetchRedirectUrl.searchParams.set('inAppPurchase', inAppPurchase);
                    fetchRedirectUrl.searchParams.set('tierId', tierId);
                    fetchRedirectUrl.searchParams.set('productId', productId);
                    fetchRedirectUrl.searchParams.set('subsId', subsId);
                  }
                  window.location.href = removeDuplicateParams(fetchRedirectUrl.href);
                  setLoading(false);
                });

                // window.location.href = fetchRedirectUrl.href;
                setLoading(false);
              }
            })
            .catch((error) => {
              console.error('error logging in with google id token', error);
              const value = JSON.stringify({ message: 'error logging in with google id token', errorCode: error });
              errorHandlerForMobile(value);
              const fetchRedirectUrl = new URL(redirectUrl);
              fetchRedirectUrl.searchParams.set('loginError', 'error loggging in with google');
              window.location.href = removeDuplicateParams(fetchRedirectUrl.href);
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          const value = JSON.stringify({ message: 'GOOGLE one tap login error', errorCode: '400' });

          errorHandlerForMobile(value);
          console.error(error);
        }
      } else {
        setLoading(false);
      }
    }
  }, [clientGroupId]);

  const getButtonText = () => {
    let text = 'Continue with One-time Passcode (OTP)';
    if ((accessMethods?.otp && accessMethods?.password) || accessMethods?.password) {
      text = 'Login';
    }
    return text;
  };
  const navigateHandle = () => {
    if (userDetails.phone) {
      handleLogin();
      return;
    }
    navigate(`/loginemail?${queryParams.toString()}`);
  };

  const handleLogin = async () => {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/login`,
        data: {
          ...((userDetails?.phone || userPhone) && {
            phone: isValidNumber(userDetails?.phone || userPhone),
            countryCode: '+91',
          }),
          email: isValidEmail(userDetails?.email || userEmail),
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
          loginType: 'otp',
          ...(isValid(commanUrlValue.paywallId) && { paywallId: commanUrlValue.paywallId }),
          ...(isValid(commanUrlValue.paywallType) && { paywallType: commanUrlValue.paywallType }),
          ...(isValid(commanUrlValue.transactionId) && { transactionId: commanUrlValue.transactionId }),
        },
      });
      if (checkSuccessCode(response)) {
        navigate(`/newvarifyotp?${queryParams.toString()}`, {
          state: { backRoute: '/login', email: userDetails?.email, phone: userDetails?.phone },
        });
      }
    } catch (error) {
      return antdMessageError(error?.response?.data?.message);
    }
  };

  const handleTokenv2 = (token) => {
    setRecaptcha({ ...recaptcha, recaptchaToken: token });
  };

  const getUserLoginDetails = async () => {
    setCTALoading(true);
    let token = '';
    if (toggleData?.captcha?.enabled && toggleData?.captcha?.onLogin && toggleData?.captcha?.captchaVersion === 3) {
      token = await recaptchaRef.current.executeAsync();
    }
    try {
      const getDetailsResponse = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/sso/check-account-status`,
        params: {
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
          email: isValidEmail(userDetails?.email || userEmail),
          phone: isValidNumber(userDetails?.phone || userPhone),
          ...(token && {
            token: token,
          }),
          ...(recaptcha?.recaptchaToken && {
            token: recaptcha?.recaptchaToken,
          }),
          type: 'onLogin',
        },
      });
      if (checkSuccessCode(getDetailsResponse)) {
        const validPassword = getDetailsResponse?.data?.validPassword;
        if (getDetailsResponse?.data?.status) {
          if (getDetailsResponse?.data?.validated) {
            const ssoLoginClickObject = {
              eventType: eventType?.CLICK,
              eventLocation: eventLocation?.SSO_LOGIN,
              clickAction: userDetails?.loginType,
              clientId: commanUrlValue?.clientId,
              anonId: commanUrlValue?.anonId,
              paywallId: commanUrlValue?.paywallId,
              url: decodeURIComponent(window.location.href || ''),
              segmentId: commanUrlValue?.segmentId,
              journeyId: commanUrlValue?.journeyId,
            };
            CSCSendEvent(ssoLoginClickObject);
            if (!validPassword || !accessMethods?.password) {
              handleLogin();
            } else {
              getButtonText() === 'Get OTP' ? handleLogin() : navigateHandle();
            }
          } else {
            antdMessageError('The reCAPTCHA token is invalid. Please try again.');
          }
          setCTALoading(false);
        } else {
          const value = JSON.stringify({
            message: "We couldn't find a registered account. Want to sign up?",
            errorCode: '404',
          });
          errorHandlerForMobile(value);
          navigate(`/personalinfo?${queryParams.toString()}`, { state: '/personalinfo' });
        }
      }
      setCTALoading(false);
    } catch (error) {
      console.log(error);
      setCTALoading(false);
      navigate(`/personalinfo?${queryParams.toString()}`, { state: '/personalinfo' });
      return antdMessageError(
        'We encountered an issue while checking your registration status. Please try again later.',
      );
    }
  };

  useEffect(() => {
    if (toggleData?.captcha?.captchaVersion === 2) {
      setRecaptcha({ ...recaptcha, recaptchaVersion: 'normal' });
    } else if (toggleData?.captcha?.captchaVersion === 3) {
      setRecaptcha({ ...recaptcha, recaptchaVersion: 'invisible' });
    }
  }, [toggleData?.captcha]);
  const userCode = localStorage.getItem('code') || '';

  useEffect(() => {
    const ssoLoginViewObject = {
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SSO_LOGIN,
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      paywallId: commanUrlValue?.paywallId,
      url: decodeURIComponent(window.location.href || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoLoginViewObject);
  }, [commanUrlValue]);

  useEffect(() => {
    userEmail && checkinput(userEmail);
    userPhone && checkinput(userPhone);
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if ((userEmail || userPhone) && getUserLoginDetailsCallCount.current < 1) {
      setCTALoading(true);
      getUserLoginDetailsCallCount.current += 1;
      setTimeout(() => {
        setCTALoading(false);
        getUserLoginDetails();
      }, 1000);
    }
  }, [toggleData]);

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      setLoading(true);
      const googleLoginResponse = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/google-login`,
        data: {
          token: tokenResponse.access_token,
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
          signUpOriginUrl: redirectUrl,
          ...(isValid(commanUrlValue.clientContentId) && { clientContentId: commanUrlValue.clientContentId }),
          ...(isValid(commanUrlValue.paywallId) && { paywallId: commanUrlValue.paywallId }),
          ...(isValid(commanUrlValue.paywallType) && { paywallType: commanUrlValue.paywallType }),
          ...(isValid(commanUrlValue.anonId) && { anonId: commanUrlValue.anonId }),
        },
      });
      localStorage.setItem('code', googleLoginResponse.data.accessToken);
      const userCode = googleLoginResponse.data.accessToken;
      if (userCode) {
        try {
          const codedata = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
            headers: { Authorization: `Bearer ${userCode}` },
          });
          if (redirectUrl) {
            if (redirectUrl === 'https://conscent-app-sdk') {
              try {
                const dataformobile = {
                  token: codedata.data.authCode,
                  status: true,
                  subsId: subsId,
                  tierID: tierId,
                  productId: productId,
                };
                loginHandlerForMobile(JSON.stringify(dataformobile));
              } catch (err) {
                console.log(err, 'error while sending data to mobile app');
              }
            } else {
              const fetchRedirectUrl = new URL(redirectUrl);
              slientLoginHandlerForMobile(
                JSON.stringify({
                  message: 'SLIENT_USER_LOGIN',
                  authToken: codedata.data.authCode,
                  statusCode: '200',
                }),
              );
              fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
              // if (queryParams.get('origin') === 'subsPage') {
              //   navigate(`/address?redirectUrl=${redirectUrl}&clientId=${clientId}&purchaseType=${purchaseType}`);
              // } else {
              const anonId = queryParams.get('anonId');
              const paywallId = queryParams.get('paywallId');
              fetchRedirectUrl.searchParams.set('anonId', anonId);
              fetchRedirectUrl.searchParams.set('paywallId', paywallId);
              fetchRedirectUrl.searchParams.set('clientId', clientId);
              fetchRedirectUrl.searchParams.set('mobileView', mobileView);
              if (inAppPurchase) {
                fetchRedirectUrl.searchParams.set('inAppPurchase', inAppPurchase);
                fetchRedirectUrl.searchParams.set('tierId', tierId);
                fetchRedirectUrl.searchParams.set('productId', productId);
                fetchRedirectUrl.searchParams.set('subsId', subsId);
              }
              // console.log((!fetchRedirectUrl.searchParams.has('productId') && productId), !fetchRedirectUrl.searchParams.has('productId'), productId , 'testingssofive_handleGoogleLogin', fetchRedirectUrl.href);
              window.location.href = removeDuplicateParams(fetchRedirectUrl.href);
              // }
            }
          } else {
            setLoading(false);
            antdMessageError('A redirect URL is required but not provided. Please check and try again.');
          }
        } catch (err) {
          try {
            // Clear localStorage
            setLoading(false);
            localStorage.clear();
            // Clear all cookies
            document.cookie.split(';').forEach((cookie) => {
              const name = cookie.split('=')[0].trim();
              document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            });
          } catch (error) {
            console.log(error);
          }
          console.log(err, 'error on generating auth code');
        }
      }
    },
    flow: 'implicit',
  });
  const callGoogleLogin = () => {
    if (mobileView) {
      googleLogInHandlerForMobile(
        JSON.stringify({
          message: 'GOOGLE_LOGIN_CLICK',
          data: {
            _id: subsId,
            tierId: tierId,
            productId: productId,
            inAppPurchase: inAppPurchase,
          },
        }),
      );
    } else {
      handleGoogleLogin();
    }
    const ssoLoginClickObject = {
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SSO_LOGIN,
      clickAction: clickAction.GOOGLE,
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      url: decodeURIComponent(redirectUrl || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoLoginClickObject);
  };
  const handleAppleLogin = async (token) => {
    setLoading(true);
    //  ...(newsLetterNameInput && {name: newsLetterNameInput }),
    try {
      const appleLoginResponse = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/apple-login`,
        data: {
          token: token,
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
          signUpOriginUrl: redirectUrl,
          ...(isValid(commanUrlValue.clientContentId) && { clientContentId: commanUrlValue.clientContentId }),
          ...(isValid(commanUrlValue.paywallId) && { paywallId: commanUrlValue.paywallId }),
          ...(isValid(commanUrlValue.paywallType) && { paywallType: commanUrlValue.paywallType }),
          ...(isValid(commanUrlValue.anonId) && { anonId: commanUrlValue.anonId }),
        },
      });
      localStorage.setItem('code', appleLoginResponse.data.accessToken);
      const userCode = appleLoginResponse.data.accessToken;
      if (userCode) {
        try {
          const codedata = await axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
            headers: { Authorization: `Bearer ${userCode}` },
          });
          if (redirectUrl) {
            if (redirectUrl === 'https://conscent-app-sdk') {
              try {
                const dataformobile = {
                  token: codedata.data.authCode,
                  status: true,
                  subsId: subsId,
                  tierID: tierId,
                  productId: productId,
                };
                loginHandlerForMobile(JSON.stringify(dataformobile));
              } catch (err) {
                console.log(err, 'error while sending data to mobile app');
              }
            } else {
              slientLoginHandlerForMobile(
                JSON.stringify({
                  message: 'SLIENT_USER_LOGIN',
                  authToken: codedata.data.authCode,
                  statusCode: '200',
                }),
              );
              const fetchRedirectUrl = new URL(redirectUrl);
              fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
              // if (queryParams.get('origin') === 'subsPage') {
              //   navigate(`/address?redirectUrl=${redirectUrl}&clientId=${clientId}&purchaseType=${purchaseType}`);
              //   setLoading(false);
              // } else {
              const anonId = queryParams.get('anonId');
              const paywallId = queryParams.get('paywallId');
              fetchRedirectUrl.searchParams.set('anonId', anonId);
              fetchRedirectUrl.searchParams.set('paywallId', paywallId);
              fetchRedirectUrl.searchParams.set('clientId', clientId);
              fetchRedirectUrl.searchParams.set('mobileView', mobileView);
              if (inAppPurchase) {
                fetchRedirectUrl.searchParams.set('inAppPurchase', inAppPurchase);
                fetchRedirectUrl.searchParams.set('tierId', tierId);
                fetchRedirectUrl.searchParams.set('productId', productId);
                fetchRedirectUrl.searchParams.set('subsId', subsId);
              }
              console.log('testingssofive', fetchRedirectUrl.href);
              window.location.href = removeDuplicateParams(fetchRedirectUrl.href);
              // }
            }
          } else {
            setLoading(false);
            antdMessageError('A redirect URL is required but not provided. Please check and try again.');
          }
        } catch (err) {
          try {
            // Clear localStorage
            setLoading(false);
            localStorage.clear();
            // Clear all cookies
            document.cookie.split(';').forEach((cookie) => {
              const name = cookie.split('=')[0].trim();
              document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            });
          } catch (error) {
            console.log(error);
          }
          console.log(err, 'error on generating auth code');
        }
      }
    } catch (error) {
      if (!checkSuccessCode(error)) {
        setLoading(false);
        antdMessageError(error?.response?.data?.message);
        return errorHandlerForMobile(JSON.stringify(error?.response?.data));
      }
    }
  };
  const handleFaceBookLogin = async (token, facebookId, facebookMail) => {
    if (token !== 'loginCancelled' && token !== 'token') {
      setLoading(true);
      try {
        const facebookLoginResponse = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}/sso/facebook-login`,
          data: {
            token: token,
            clientGroupId: clientGroupId,
            clientId: commanUrlValue.clientId,
            signUpOriginUrl: redirectUrl,
            // paywallId: commanUrlValue.paywallId,
            // paywallType: commanUrlValue.paywallType,
            // clientContentId:commanUrlValue.clientContentId,
            // anonId:commanUrlValue.anonId,
            ...(isValid(commanUrlValue.clientContentId) && { clientContentId: commanUrlValue.clientContentId }),
            ...(isValid(commanUrlValue.paywallId) && { paywallId: commanUrlValue.paywallId }),
            ...(isValid(commanUrlValue.paywallType) && { paywallType: commanUrlValue.paywallType }),
            ...(isValid(commanUrlValue.anonId) && { anonId: commanUrlValue.anonId }),
            ...(facebookId && { facebookId }),
            ...(facebookMail && { email: facebookMail }),
          },
        });
        localStorage.setItem('code', facebookLoginResponse.data.accessToken);
        const userCode = facebookLoginResponse.data.accessToken;
        if (userCode) {
          try {
            const codedata = await axios({
              method: 'POST',
              url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
              headers: { Authorization: `Bearer ${userCode}` },
            });
            if (redirectUrl) {
              if (redirectUrl === 'https://conscent-app-sdk') {
                try {
                  const dataformobile = {
                    token: codedata.data.authCode,
                    status: true,
                    subsId: subsId,
                    tierID: tierId,
                    productId: productId,
                  };
                  loginHandlerForMobile(JSON.stringify(dataformobile));
                } catch (err) {
                  console.log(err, 'error while sending data to mobile app');
                }
              } else {
                slientLoginHandlerForMobile(
                  JSON.stringify({
                    message: 'SLIENT_USER_LOGIN',
                    authToken: codedata.data.authCode,
                    statusCode: '200',
                  }),
                );
                const fetchRedirectUrl = new URL(redirectUrl);
                fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
                const anonId = queryParams.get('anonId');
                const paywallId = queryParams.get('paywallId');
                fetchRedirectUrl.searchParams.set('anonId', anonId);
                fetchRedirectUrl.searchParams.set('paywallId', paywallId);
                fetchRedirectUrl.searchParams.set('clientId', clientId);
                fetchRedirectUrl.searchParams.set('mobileView', mobileView);
                if (inAppPurchase) {
                  fetchRedirectUrl.searchParams.set('inAppPurchase', inAppPurchase);
                  fetchRedirectUrl.searchParams.set('tierId', tierId);
                  fetchRedirectUrl.searchParams.set('productId', productId);
                  fetchRedirectUrl.searchParams.set('subsId', subsId);
                }
                window.location.href = removeDuplicateParams(fetchRedirectUrl.href);
              }
            } else {
              setLoading(false);
              antdMessageError('A redirect URL is required but not provided. Please check and try again.');
            }
          } catch (err) {
            try {
              // Clear localStorage
              setLoading(false);
              localStorage.clear();
              // Clear all cookies
              document.cookie.split(';').forEach((cookie) => {
                const name = cookie.split('=')[0].trim();
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
              });
            } catch (error) {
              console.log(error);
            }
            console.log(err, 'error on generating auth code');
          }
        }
      } catch (error) {
        if (!checkSuccessCode(error)) {
          setLoading(false);
          antdMessageError(error?.response?.data?.message);
          return errorHandlerForMobile(JSON.stringify(error?.response?.data));
        }
      }
    }
  };

  useEffect(() => {
    if (apple_id_token) {
      handleAppleLogin(apple_id_token);
    } else if (facebook_id_token) {
      handleFaceBookLogin(facebook_id_token, facebookId, facebookMail);
    }
  }, [apple_id_token, facebook_id_token]);

  useEffect(() => {
    if (isGoogleLoginFlow) {
      setTimeout(() => {
        callGoogleLogin();
      }, 600);
    } else if (isAppleLoginFlow) {
      setTimeout(() => {
        if (appleLoginButtonRef.current) {
          appleLoginButtonRef.current.click();
        }
      }, 600);
    } else if (isFacebookLoginFlow) {
      setTimeout(() => {
        if (facebookLoginButtonRef.current) {
          facebookLoginButtonRef.current.click();
        }
      }, 600);
    }
  }, []);
  useEffect(() => {
    const isAppleLogin = queryParams.get('isAppleLogin');
    setTimeout(() => {
      if (isAppleLogin === 'true') {
        document.getElementById('appleLogin').click();
      }
    }, 400);
  }, []);
  useEffect(() => {
    const isFaceBookLogin = queryParams.get('isFaceBookLogin');
    setTimeout(() => {
      if (isFaceBookLogin === 'true') {
        document.getElementById('faceBookLogin').click();
      }
    }, 400);
  }, []);
  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      if (!btnDisabled) {
        getUserLoginDetails();
      }
    }
  };

  if (userCode || loading) {
    return <NewLoader />;
  }

  return (
    <div className={loginStyle['main-container']}>
      <div style={{ flexGrow: 1 }}>
        <div className={loginStyle['header-logo']}>
          <img
            onClick={() => redirectToDomain(websiteDomain)}
            className={loginStyle['image-logo']}
            src={toggleData?.brandLogoUrl || Demo_image}
            alt="Demo_image"
          />
        </div>
        <div className={loginStyle['container']}>
          <div className={loginStyle['child-container']}>
            <div className={loginStyle['heading-container']}>
              <div>
                <div className={loginStyle['heading-text']} style={data?.loginFormTitle}>
                  {data?.loginFormTitle?.text || 'Welcome back'}
                </div>
                <div className={loginStyle['sub-text']} style={data?.signupLinkText}>
                  {/* {data?.signupLinkText?.text || 'Please enter your valid details below'} */}
                </div>
              </div>
            </div>
            <div className={loginStyle['form-container']}>
              {loginMethods?.enableEmailLogin && loginMethods?.enableMobileLogin ? (
                <div className={loginStyle['input-container-common']}>
                  <img src={Profile_icon} alt="Profile_icon" />
                  <Input
                    placeholder={data?.loginInputText?.text || 'Email / Mobile'}
                    style={data?.loginInputText}
                    ref={inputRef}
                    onChange={(e) => checkinput(e.target.value)}
                    value={userDetails?.emailPhone}
                    onKeyDown={keyDownHandler}
                  />
                </div>
              ) : (
                <>
                  {loginMethods?.enableMobileLogin && (
                    <div className={loginStyle['input-container']}>
                      <img src={Mobile_icon} alt="Mobile_icon" />
                      <div className={loginStyle['country-code']}>+91</div>
                      <div className={loginStyle['vertical-line']}></div>
                      <Input
                        placeholder={data?.loginInputText?.text || '976537263636'}
                        style={data?.loginInputText}
                        onChange={(e) => {
                          const reg = /^-?\d+(\.\d*)?$/;
                          if (reg.test(e.target.value) || e.target.value === '') {
                            checkinput(e.target.value);
                          }
                        }}
                        value={userDetails?.emailPhone}
                        ref={inputRef}
                        onKeyDown={keyDownHandler}
                      />
                    </div>
                  )}
                  {loginMethods?.enableEmailLogin && (
                    <div className={loginStyle['input-container-common']}>
                      <img src={Email_icon} alt="Email_icon" />
                      <Input
                        placeholder={data?.loginInputText?.text || 'Email'}
                        style={data?.loginInputText}
                        onChange={(e) => checkinput(e.target.value)}
                        value={userDetails?.emailPhone}
                        ref={inputRef}
                        onKeyDown={keyDownHandler}
                      />
                    </div>
                  )}
                </>
              )}
              {toggleData?.captcha?.enabled && toggleData?.captcha?.onLogin && recaptcha?.recaptchaVersion && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size={recaptcha?.recaptchaVersion}
                    sitekey={toggleData?.captcha?.captchaKey}
                    onChange={handleTokenv2}
                  />
                </div>
              )}
            </div>
            <button
              className={loginStyle['btn-style']}
              disabled={btnDisabled}
              style={{
                ...data?.buttonText,
                opacity: btnDisabled ? '0.5' : '1',
                cursor: btnDisabled ? 'not-allowed' : 'pointer',
              }}
              onClick={() => {
                getUserLoginDetails();
              }}
            >
              {ctaLoading ? <CTALoader /> : data?.buttonText?.text || getButtonText()}
            </button>
            {(loginMethods?.enableGoogleLogin || loginMethods?.enableFacebookLogin || loginMethods?.enableAppleLogin) &&
            !hideSocialLogin ? (
              <div className={loginStyle['continueWithContainer']}>
                <hr className={loginStyle['horizontalLine']} />
                <div className={loginStyle['continueWith']} style={data?.dividerText}>
                  {data?.dividerText?.text || 'Or'}
                </div>
                <hr className={loginStyle['horizontalLine']} />
              </div>
            ) : (
              ''
            )}
            {loginMethods?.enableGoogleLogin && !hideSocialLogin && (
              <button className={loginStyle['google-btn']} onClick={() => callGoogleLogin()}>
                <img
                  src="https://stage0conscent.blob.core.windows.net/stage-conscent-public/google-logo.svg"
                  alt="Google logo"
                />
                <div style={data?.googleSsoText}>{data?.googleSsoText?.text || 'Continue with Google'}</div>
              </button>
            )}
            {loginMethods?.enableFacebookLogin && !hideSocialLogin && (
              <FacebookLogin
                appId={toggleData?.facebookAppId}
                scope="public_profile,email"
                redirectURI={window.location.origin}
                onSuccess={(res) => handleFaceBookLogin(res?.accessToken, '', '')}
                onFail={(res) => handleFaceBookLogin(res.status, '', '')}
                render={(props) => (
                  <button
                    className={loginStyle['facebook-btn']}
                    id="faceBookLogin"
                    ref={facebookLoginButtonRef} // Assign the reference to the button
                    onClick={() => {
                      if (mobileView) {
                        facebookLogInHandlerForMobile(
                          JSON.stringify({
                            message: 'FACEBOOK_LOGIN_CLICK',
                            data: {
                              _id: subsId,
                              tierId: tierId,
                              productId: productId,
                              inAppPurchase: inAppPurchase,
                            },
                          }),
                        );
                      } else {
                        props.onClick();
                      }
                    }}
                  >
                    <img src={Facebook_icon} alt="Facebook_icon" />
                    <div style={data?.facebookSsoText}>{data?.facebookSsoText?.text || 'Continue with Facebook'}</div>
                  </button>
                )}
              />
            )}
            {loginMethods?.enableAppleLogin && showAppleLogin && !hideSocialLogin && (
              <AppleLogin
                clientId={toggleData?.appleServiceId} // Your Service ID
                redirectURI={window.location.origin} // Your Redirect URI
                usePopup={true} // or false, depending on your implementation
                scope="name email"
                render={(props) => (
                  <button
                    className={loginStyle['google-btn']}
                    style={{ marginTop: '25px' }}
                    id="appleLogin"
                    ref={appleLoginButtonRef} // Assign the reference to the button
                    onClick={() => {
                      if (mobileView) {
                        appleLogInHandlerForMobile(
                          JSON.stringify({
                            message: 'APPLE_LOGIN_CLICK',
                            data: {
                              _id: subsId,
                              tierId: tierId,
                              productId: productId,
                              inAppPurchase: inAppPurchase,
                            },
                          }),
                        );
                      } else {
                        props.onClick();
                      }
                    }}
                  >
                    <img src={Apple_icon} alt="Apple_icon" />
                    <div style={data?.appleSsoText}>{data?.appleSsoText?.text || 'Continue with Apple'}</div>
                  </button>
                )}
                callback={(token) => {
                  if (token?.authorization?.id_token) {
                    handleAppleLogin(token?.authorization?.id_token);
                  } else {
                    console.error(token?.error?.error);
                  }
                }}
              />
            )}
            <div className={loginStyle['by-continue']} style={data?.TandCtext}>
              {data?.TandCtext?.text || 'By continuing, you agree to our'}{' '}
              <a className={loginStyle['by-noHover']} href={toggleData?.redirects?.tnc} rel="noopener noreferrer" >
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a
                className={loginStyle['by-noHover']}
                href={toggleData?.redirects?.privPolicy}
                rel="noopener noreferrer"
                
              >
                Privacy Policy
              </a>
            </div>
          </div>

          <div
            onClick={() => {
              callGoogleLogin();
            }}
            id="google"
          ></div>
        </div>
      </div>
      <Footer termsAndConditions={toggleData?.redirects?.tnc} privacyAndPolicy={toggleData?.redirects?.privPolicy} />
    </div>
  );
}
