import React, { useState, useEffect, useRef } from 'react';
import style from '../Style/Navigator.module.css';
import Button from './button';
import axios from 'axios';
import Edit from './edit';
import Subscription from './Subscription';
import Transaction from './Transaction';
import PasswordCreation from './Pwdcreation';
import DeleteAccount from './DeleteAccount';
import { ForgotPassword } from '../NewLogin/forgetPassword';
import Arrow from './icons/Vector';
import Subscriber from './icons/subscriber';
import User from './icons/user';
import Card from './icons/card';
import Key from './icons/key';
import EditCard from './EditCardDetails';
import Deleteuser from './icons/deleteuser';
import Cards from './icons/manageCards';
import SubsIcon from './icons/SubsIcon';
import Loader from './loader';
import CrossIcon from './icons/crossIcon';
import { antdMessageError, antdMessageWarning } from '../../Components/common/antdMessageComponent';
import { CSCGetAccessToken } from './utills';
import { checkSuccessCode, logOutHandlerForMobile } from '../../utills/validator';
import ManageCards from './ManageCards';
import UpgradePlan from './UpgradePlan';

const Navigator = () => {
  const [data, setData] = useState();
  const user = data?.name || data?.email || data?.phoneNumber || '';
  const [font, setfont] = useState('48px');
  const [screen, setscreen] = useState('userdetails');
  const [userDrawerPositionRight, setUserDrawerPositionRight] = useState(); //used to set the user details position right or left use function set right for that
  const [leftClose, setLeftClose] = useState(true);
  const [mobileCloseButton, setMobileCloseButton] = useState(true);
  const [transaction, settransaction] = useState(false);
  const [subs, setsubs] = useState(false);
  const [isClickable1, setIsClickable1] = useState();
  const [isClickable2, setIsClickable2] = useState();
  const [openLeftDrawer, setOpenLeftDrawer] = useState(false);
  const [openRightDrawer, setOpenRightDrawer] = useState(false);
  const [closeLeftDrawer, setCloseLeftDrawer] = useState(false);
  const [closeRightDrawer, setCloseRightDrawer] = useState(false);
  const [Plans, setPlans] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const mobileView = queryParams.get('mobileView') === 'true' ? true : false;
  const clientConfig = JSON.parse(localStorage.getItem('commonUrlData')); 
  const width = useRef(window.innerWidth); 
  const textColor = '#00063d';
  const backgroundColor = '#f5f5ff';
  const highlightColor = '#7583ff';
  const logoutColor = '#949fff';
  const fontstyle = 'poppins';

  const background = data?.userProfileCustomization?.backgroundColor || backgroundColor;
  const clientId = queryParams.get('clientId');
  const logout = () => {
    let mobileView = queryParams.get('mobileView') === 'true' ? true : false;
    let clientId = queryParams.get('clientId');
    let clientContentId = queryParams.get('clientContentId');
    if (mobileView) {
      window.location.href =
        window.location.origin + `/logout?redirectUrl=https://conscent-app-sdk&clientId=${clientId}`;
    }
    window.parent.postMessage({ source: 'logout-from-user' }, '*');
  };
  const refreshToken = queryParams.get('refreshToken');
  const toggleCloseleft = () => {
    window.parent.postMessage({ source: 'userDetailAction' }, '*');
    const source = queryParams.get('source');
    const redirectUrl = queryParams.get('redirectUrl');
    setCloseLeftDrawer(true);
    setTimeout(() => {
      setOpenLeftDrawer(false);
      setCloseLeftDrawer(true); // Reset the closing state for potential future openings
      setLeftClose(false); //for left drawer close button display on open and close respectively
      setMobileCloseButton(false); // for the display of mobile close button visibility
    }, 490); // Match the duration of the CSS transition
    if (source === 'AMP') {
      window.location.href = redirectUrl;
    }
  };
  useEffect(() => {
    const secondaryDetails = queryParams.get('secondaryDetails');
    const transactionId = queryParams.get('transactionId');
    if (secondaryDetails === 'true') {
      setscreen('edit');
    } else if (transactionId) {
      setscreen('transaction');
    }
  }, []);

  const toggleCloseright = () => {
    window.parent.postMessage({ source: 'userDetailAction' }, '*');
    const source = queryParams.get('source');
    const redirectUrl = queryParams.get('redirectUrl');
    setCloseRightDrawer(true);
    setTimeout(() => {
      setOpenRightDrawer(false);
      setCloseRightDrawer(true); // Reset the closing state for potential future openings
      setMobileCloseButton(false); // for the display of mobile close button visibility
    }, 490); // Match the duration of the CSS transition
    if (source === 'AMP') {
      window.location.href = redirectUrl;
    }
  };

  const toggleUserDrawerLeft = () => {
    setOpenLeftDrawer(true);
    setTimeout(() => {
      setOpenLeftDrawer(true);
      setCloseLeftDrawer(false); // Reset the closing state for potential future openings
    }); // Match the duration of the CSS transition
  };

  const toggleUserDrawerRight = () => {
    setOpenRightDrawer(true);
    setTimeout(() => {
      setOpenRightDrawer(true);
      setCloseRightDrawer(false); // Reset the closing state for potential future openings
    }); // Match the duration of the CSS transition
  };

  useEffect(() => {
    settransaction(data?.userProfileCustomization?.hasTransactions);
    setsubs(data?.userProfileCustomization?.hasSubscriptions);

    if (data?.userProfileCustomization?.drawerPosition === 'RIGHT') {
      setUserDrawerPositionRight(0);
      toggleUserDrawerRight();
    } else {
      setUserDrawerPositionRight();
      toggleUserDrawerLeft();
    }
  }, [data]);

  const handleUser = (user) => {
    if (user.length >= 25) {
      return `${user.substring(0, 28)}...`;
    } else {
      return user;
    }
  };

  // API calls
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const refreshToken = queryParams.get('refreshToken');

      if (refreshToken && refreshToken !== 'null') {
        const accessToken = await CSCGetAccessToken(refreshToken);

        if (checkSuccessCode(accessToken)) return;

        localStorage.setItem('accessToken', accessToken);
        await fetchData(accessToken);

        if (userDrawerPositionRight === null) {
          toggleUserDrawerLeft();
        } else {
          toggleUserDrawerRight();
        }
      } else {
        antdMessageWarning('It seems your account has been logged out. Please try logging in again');
        logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
        window.parent.postMessage({ source: 'logout-from-user' }, '*');
      }
    })();
  }, [screen]);

  // Define an async function to fetch data
  const fetchData = async (Token) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/authorization/get-loggedin-user-details`,
        headers: { Authorization: `Bearer ${Token}` },
      });
      if (response?.status === 200) {
        setData(response.data);
        setLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        antdMessageError('It seems your account has been logged out. Please try logging in again');
        localStorage.clear();
        logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
        window.parent.postMessage({ source: 'logout-from-user' }, '*');
      }
    }
  };

  useEffect(() => {
    if (user.length >= 10) {
      setfont('30px');
    }
    if (user.length >= 15) {
      setfont('26px');
    }
    if (user.length >= 20) {
      setfont('25px');
    }
    if (user.length >= 23) {
      setfont('23px');
    }
    if (user.length >= 25) {
      setfont('20px');
    }
  }, [user]);

  useEffect(() => {
    settransaction(data?.hasTransactions);
    setsubs(data?.hasSubscriptions);
    setIsClickable1(data?.hasSubscriptions);
    setIsClickable2(data?.hasTransactions);
  }, [data]);

  return (
    <>
      {loading && (
        <div>
          <Loader message="Fetching Data" />
        </div>
      )}

      {mobileCloseButton && !loading && !mobileView && screen === 'userdetails' && (
        <div className={style['div4mo']}>
          <div className={style['close']} onClick={userDrawerPositionRight === 0 ? toggleCloseright : toggleCloseleft}>
            <CrossIcon fill={data?.userProfileCustomization?.highlightColor || highlightColor} />
          </div>
        </div>
      )}
      {/* ) : (
        ''
      )} */}

      {!loading && (openLeftDrawer || openRightDrawer) && (
        <div
          className={`${style['card-container']} ${
            userDrawerPositionRight === 0
              ? `${closeRightDrawer && style['closing1']}`
              : `${closeLeftDrawer && style['closing']}`
          }`}
          id="card-container"
          style={{
            right: userDrawerPositionRight,
            background: data?.userProfileCustomization?.backgroundColor || backgroundColor,
            fontFamily: data?.userProfileCustomization?.fontStyle || fontstyle,
            color: data?.userProfileCustomization?.primaryTextColor || textColor,
          }}
        >
          <div className={style['details-container']}>
            {screen === 'userdetails' && (
              <div className={style['close2']} onClick={toggleCloseright}>
                <CrossIcon fill={data?.userProfileCustomization?.highlightColor || highlightColor} />
              </div>
            )}

            <div
              className={style['username']}
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: font,
                borderBottom: `1px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}`,
              }}
            >
              {handleUser(user)}
            </div>

            <div className={style['account']}>
              <p className={style['span_head']}>Account Details</p>
              <div
                className={style['options']}
                onClick={() => setscreen('edit')}
                style={{ borderBottom: `.8px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}` }}
              >
                {/* inner elements of single option */}
                <div className={style['inner']}>
                  <User
                    fill={data?.userProfileCustomization?.highlightColor || highlightColor}
                    className={style['icons']}
                  />
                  <div className={style['topic']}>Edit Your Profile</div>
                </div>
                <div className={style['arrow']}>
                  <Arrow fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
                </div>
              </div>

              <div
                className={`${style['options']} ${!isClickable1 && style['blur']}`}
                style={{
                  cursor: isClickable1 ? 'pointer' : 'not-allowed',
                  borderBottom: `.8px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}`,
                }}
                onClick={isClickable1 ? () => setscreen('subscription') : null}
              >
                {/* inner elements of single option */}
                <div className={style['inner']}>
                  <Subscriber
                    fill={data?.userProfileCustomization?.highlightColor || highlightColor}
                    className={style['icons']}
                  />
                  <div className={style['topic']}>Your Subscription</div>
                </div>
                <span className={style['arrow']}>
                  <Arrow fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
                </span>
              </div>

              <div
                className={`${style['options']} ${!isClickable2 && style['blur']}`}
                style={{
                  cursor: isClickable2 ? 'pointer' : 'not-allowed',
                  borderBottom: `.8px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}`,
                }}
                onClick={isClickable2 ? () => setscreen('transaction') : null}
              >
                {/* inner elements of single option */}
                <div className={style['inner']}>
                  <Card
                    fill={data?.userProfileCustomization?.highlightColor || highlightColor}
                    className={style['icons']}
                  />
                  <div className={style['topic']}> Transaction Details</div>
                </div>
                <span className={style['arrow']}>
                  <Arrow fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
                </span>
              </div>
            </div>
            {data?.isEmailVerified && (
              <div className={style['settings']}>
                <p className={style['span_head']}>Settings</p>
                {data?.isEmailVerified && (
                  <div
                    className={style['options']}
                    onClick={() => setscreen('password')}
                    style={{ borderBottom: `.8px solid ${data?.userProfileCustomization?.primaryTextColor || '#fff'}` }}
                  >
                    {/* inner elements of single option */}
                    <div className={style['inner']}>
                      <Key
                        fill={data?.userProfileCustomization?.highlightColor || highlightColor}
                        className={style['icons']}
                      />
                      <div className={style['topic']}>{data?.hasPassword ? 'Change Password' : 'Create Password'}</div>
                    </div>
                    <span className={style['arrow']}>
                      <Arrow fill={data?.userProfileCustomization?.primaryTextColor || '#fff'} />
                    </span>
                  </div>
                )}

                {data?.userProfileCustomization?.managePayments === 'SHOW' && (
                  <div
                    className={style['options']}
                    onClick={() => {
                      setscreen('cards');
                    }}
                    style={{
                      borderBottom: `.8px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}`,
                    }}
                  >
                    <div className={style['inner']}>
                      <Cards
                        fill={data?.userProfileCustomization?.highlightColor || highlightColor}
                        className={style['icons']}
                      />
                      <div className={style['topic']}>Manage Payments</div>
                    </div>
                    <span className={style['arrow']}>
                      <Arrow fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
                    </span>
                  </div>
                )}
                {(data?.roles === 'ADMIN' && width.current > 768) && (
                  <div
                    className={style['options']}
                    onClick={() => {
                      window.open(`${window.location.origin}/user-management?clientId=${clientId}&refreshToken=${refreshToken}`, '_blank', 'noopener,noreferrer');
                    }}
                    style={{ borderBottom: `.8px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}` }}
                  >
                    <div className={style['inner']}>
                      <SubsIcon
                        fill={data?.userProfileCustomization?.highlightColor || highlightColor}
                        className={style['icons']}
                      />
                      <div className={style['topic']}>Manage Subscribers</div>
                    </div>
                    <span className={style['arrow']}>
                      <Arrow fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
                    </span>
                  </div>
                )}

                {/* <div
                className={style['options']}
                onClick={() => setscreen('delete')}
                style={{ borderBottom: `.8px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}` }}
              >
                <div className={style['inner']}>
                  <Deleteuser
                    fill={data?.userProfileCustomization?.highlightColor || highlightColor}
                    className={style['icons']}
                  />
                  <div className={style['topic']}>Delete Account</div>
                </div>
                <span className={style['arrow']}>
                  <Arrow fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
                </span>
              </div> */}
              </div>
            )}
          </div>
          {screen === 'userdetails' && (
            <div className={style['button-container']}>
              <Button
                className={style['btn']}
                color={data?.userProfileCustomization?.logOutButtonColor || logoutColor}
                font={data?.userProfileCustomization?.fontStyle}
                textcolor={data?.userProfileCustomization?.logOutButtonTextColor || textColor}
                fnc={logout}
                text="Log Out"
                boxshadow="0 0 2px"
              />
            </div>
          )}
          {leftClose && screen === 'userdetails' && (
            <div
              className={style['close1']}
              onClick={toggleCloseleft}
              style={{ display: userDrawerPositionRight === 0 ? 'none' : 'block' }}
            >
              <CrossIcon fill={data?.userProfileCustomization?.highlightColor || highlightColor} />
            </div>
          )}
        </div>
      )}

      {screen === 'edit' && (
        <Edit
          screen={screen}
          setscreen={setscreen}
          dataa={data}
          rightvaluepassed={userDrawerPositionRight}
          color={background}
        />
      )}
      {screen === 'subscription' && (
        <Subscription
          screen={screen}
          setscreen={setscreen}
          data={data}
          rightvaluepassed={userDrawerPositionRight}
          color={background}
          text={data?.clientName}
          setPlans={setPlans}
        />
      )}
      {screen === 'transaction' && (
        <Transaction
          screen={screen}
          setscreen={setscreen}
          data={data}
          rightvaluepassed={userDrawerPositionRight}
          color={background}
        />
      )}
      {screen === 'forgotpwd' && (
        <ForgotPassword
          screen={screen}
          setscreen={setscreen}
          // data={data}
          // rightvaluepassed={userDrawerPositionRight}
          // color={background}
        />
      )}
      {screen === 'password' && (
        <PasswordCreation
          screen={screen}
          setscreen={setscreen}
          data={data}
          rightvaluepassed={userDrawerPositionRight}
          color={background}
        />
      )}
      {screen === 'delete' && (
        <DeleteAccount
          screen={screen}
          setscreen={setscreen}
          data={data}
          rightvaluepassed={userDrawerPositionRight}
          color={background}
          logout={logout}
        />
      )}
      {screen === 'cards' && (
        <ManageCards setscreen={setscreen} Data={data} rightvaluepassed={userDrawerPositionRight} color={background} />
      )}
      {screen === 'EditCard' && (
        <EditCard setscreen={setscreen} Data={data} rightvaluepassed={userDrawerPositionRight} color={background} />
      )}
      {screen === 'UpgradePlan' && (
        <UpgradePlan
          plan={Plans}
          setscreen={setscreen}
          Data={data}
          rightvaluepassed={userDrawerPositionRight}
          color={background}
          clientInfo={clientConfig}
        />
      )}
    </>
  );
};

export default Navigator;
