import React, { useEffect, useState } from 'react';
import style from './UserManagement.module.scss';
import Demo_Image from '../assets/UserManagement/Demo_Image_Icon.svg';
import Arrow_icon from '../assets/UserManagement/Arrow_icon.svg';
import lineSolid from '../assets/UserManagement/line_icon.svg';
import hamburger from '../assets/UserManagement/hamburger.svg';
import { ReactComponent as InfoIcon } from '../assets/UserManagement/Info_grey_icon.svg';
import { ReactComponent as UploadIcon } from '../assets/UserManagement/Upload_Icon.svg';
import { ReactComponent as AddIcon } from '../assets/UserManagement/white_Plus_icon.svg';
import { ReactComponent as WarningIcon } from '../assets/UserManagement/warning_icon.svg';
import { ReactComponent as DeleteIcon } from '../assets/UserManagement/delete_white_icon.svg';
import calenderIcon from '../assets/UserManagement/calender_icon.svg';
import DropDownArrow from '../../Components/assets/loginsettings/dropDownArrow.svg';
import { ReactComponent as DownloadIcon } from '../assets/UserManagement/download_icon.svg';
import clsx from 'clsx';
import { ConfigProvider, DatePicker, Input, Table, Tooltip, message } from 'antd';
import { CaretRightOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import CustomDropDownForSelect from '../../Ui/DropDown/CustomDropDown';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { TableColumns } from './Const';
import CommonModal from '../../utills/CommonModal/CommonModal';
// import CurrentDateTime from './CurrentDateTime';
import { creatUser, deleteUser, fetchUsers, getSubscription, updateUser } from '../../api/api';
import { useSelector } from 'react-redux';
import { redirectToDomain } from '../user/utills';
import { sanitizedEmailRegex, sanitizedValueRegex } from '../../common/sanitizeRegex';
dayjs.extend(isBetween);

const UserManagement = () => {
  const [tableData, setTableData] = useState([]);
  const toggleData = useSelector((state) => state.loginSetting);
  const websiteDomain = useSelector((state) => state.websiteDomain);
  const [isModalVisible, setIsModalVisible] = useState({
    addModal: false,
    deleteModal: false,
  });
  const [bulkModal, setBulkModal] = useState(false);

  //filters states

  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDays, setSelectedDays] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  // filters states ends here

  const [logout, setLogout] = useState(false);
  const [showEditDelete, setShowEditDelete] = useState({
    show: false,
    id: 0,
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [isRevoked, setIsRevoked] = useState(false);
  const [licenseCount, setLicenseCount] = useState({
    name: '',
    totalLicenses: 0,
    usedLicenses: 0,
    licensesLeft: 0,
  });
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [addNewUser, setAddNewUser] = useState({
    name: '',
    email: '',
    subscriptionId: '',
  });

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // bulk style popup
  const uploadContainerStyle = {
    border: '1px solid #D9D9D9',
    borderRadius: '3px',
    background: '#FFFFFF',
    padding: '5px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '300px',
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validExtensions = ['csv'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!validExtensions.includes(fileExtension)) {
        message.error('Only CSV and Excel files are allowed!');
        e.target.value = '';
        setSelectedFile(null);
        return;
      }
      setSelectedFile(file);
    }
  };

  //search filters functions
  const applyFilters = (from, to, query, days) => {
    let filtered = [...tableData];

    if (days) {
      const targetDate = dayjs().subtract(days, 'day').startOf('day');
      filtered = filtered.filter((item) => {
        const itemDate = dayjs(item.subAddedOn.props.children, 'DD MMM YYYY');
        return itemDate.isSameOrAfter(targetDate);
      });
    }

    if (from && to) {
      filtered = filtered.filter((item) => {
        const itemDate = dayjs(item.subAddedOn.props.children, 'DD MMM YYYY');
        return itemDate.isBetween(from, to, 'day', '[]');
      });
    }

    if (query) {
      filtered = filtered.filter((item) => item.userName.props.children.toLowerCase().includes(query.toLowerCase()));
    }

    setFilteredData(filtered);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    applyFilters(fromDate, toDate, value, selectedDays);
  };

  const handleDateChange = (dates) => {
    setFromDate(dates.from || fromDate);
    setToDate(dates.to || toDate);
    applyFilters(dates.from || fromDate, dates.to || toDate, searchQuery, selectedDays);
  };

  const handleDaysFilterChange = (days) => {
    setSelectedDays(days);
    applyFilters(fromDate, toDate, searchQuery, days);
  };

  //search filters functions ends here

  const handleDeleteUser = async () => {
    let response;
    try {
      if (isRevoked) {
        response = await updateUser(selectedUserId, addNewUser);
        setIsModalVisible({ ...isModalVisible, deleteModal: false });
        setIsRevoked(false);
      } else {
        response = await deleteUser(selectedUserId);
        setIsModalVisible({ ...isModalVisible, deleteModal: false });
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };
  const setEditUser = (user) => {
    const newStatus = user.status === 'Access Revoked' ? 'Active' : 'Access Revoked';
    const updatedUser = {
      name: user.name,
      email: user.email,
      subscriptionId: user.subscriptionId,
    };
    updatedUser.status = newStatus;
    setAddNewUser(updatedUser);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let sanitizedValue;
    if (name === 'name') {
      sanitizedValue = value.replace(sanitizedValueRegex, '');
    } else {
      sanitizedValue = value.replace(sanitizedEmailRegex, '');
    }
    setAddNewUser((prevData) => ({
      ...prevData,
      [name]: sanitizedValue,
    }));
  };
  const handleDropdownChange = (value) => {
    setAddNewUser((prevData) => ({
      ...prevData,
      subscriptionId: value,
    }));
  };
  const handleSaveChanges = async () => {
    let response;
    try {
      const formData = new FormData();
      if (!selectedFile) {
        formData.append('name', addNewUser.name);
        formData.append('email', addNewUser.email);
        formData.append('adminPurchaseId', addNewUser.subscriptionId);
      }
      if (selectedFile && bulkModal) {
        formData.append('csvFile', selectedFile);
      }
      if (isEditMode) {
        response = await updateUser(selectedUserId, formData);
        setIsModalVisible({ ...isModalVisible, addModal: false });
        setIsEditMode(false);
      } else {
        response = await creatUser(formData);
        setIsModalVisible({ ...isModalVisible, addModal: false });
      }
      setAddNewUser({
        name: '',
        email: '',
        subscriptionId: '',
      });
      setSelectedFile(null);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };
  const handleRemoveFile = (e) => {
    e.stopPropagation();
    setSelectedFile(null);
    document.getElementById('fileInput').value = '';
  };
  const handleModalRemoveFile = (e) => {
    e.stopPropagation();
    setSelectedFile(null);
    document.getElementById('fileInput').value = '';
    setBulkModal(false);
  };

  const RetailSubscription = async () => {
    const responseData = await fetchUsers();
    const licenseUsers = responseData.licenses;
    setLicenseCount({
      name: responseData.name,
      totalLicenses: responseData.totalLicenses,
      usedLicenses: responseData.usedLicenses,
      licensesLeft: responseData.licensesLeft,
    });

    const pushTableData = [];
    for (let i = 0; i < licenseUsers.length; i++) {
      pushTableData.push({
        userName: <div className={style['table-title']}>{licenseUsers[i].name}</div>,
        email: <div className={style['table-value']}>{licenseUsers[i].email}</div>,
        planName: <div className={style['table-value']}>{licenseUsers[i].subscriptionPlan}</div>,
        subAddedOn: <div className={style['table-value']}>{licenseUsers[i].createdAt}</div>,
        status: (
          <div
            className={style['status']}
            style={
              licenseUsers[i].status === 'Pending'
                ? { color: '#D39C1D', border: '0.5px solid #D39C1D', backgroundColor: '#FBF5E8' }
                : licenseUsers[i].status === 'Access Revoked' || licenseUsers[i].status === 'Account Deleted'
                ? { color: '#D62525', border: '0.5px solid #D62525', backgroundColor: '#FDF4F4' }
                : {}
            }
          >
            {licenseUsers[i].status}
          </div>
        ),
        action: (
          <div className={style['wrap-img-menu']}>
            {showEditDelete?.show && showEditDelete?.id === i + 1 && (
              <div className={style['edit-delete-update-wrap']}>
                {/* <div
                  className={style['text']}
                  onClick={() => {
                    setIsModalVisible({ ...isModalVisible, addModal: true });
                    setIsEditMode((prev) => (prev = true));
                    setEditUser(licenseUsers[i]);
                    setSelectedUserId(licenseUsers[i]?._id);
                  }}
                >
                  Edit
                  <CaretRightOutlined className={style['arrow']} rev={undefined} />
                </div> */}
                <div
                  className={style['red-text']}
                  onClick={() => {
                    setIsModalVisible({ ...isModalVisible, deleteModal: true });
                    setEditUser(licenseUsers[i]);
                    setSelectedUserId(licenseUsers[i]?._id);
                    setIsRevoked(true);
                  }}
                >
                  {licenseUsers[i].status === 'Access Revoked' ? 'Reactivate' : 'Revoke'}
                </div>
                <div
                  className={style['red-text']}
                  onClick={() => {
                    setIsModalVisible({ ...isModalVisible, deleteModal: true });
                    setSelectedUserId(licenseUsers[i]?._id);
                  }}
                >
                  Delete
                </div>
              </div>
            )}
            <div
              className={style['edit-delete-update-img']}
              onClick={() => {
                setShowEditDelete((prev) => ({
                  show: !(prev.show && prev.id === i + 1), // Toggle logic
                  id: i + 1,
                }));
              }}
            >
              <img src={hamburger} alt="is edit" />
            </div>
          </div>
        ),
        key: i,
      });
    }
    setTableData(pushTableData.reverse());
    setFilteredData(pushTableData);
    return pushTableData;
  };
  const exportToCSV = async () => {
    const tableData = await RetailSubscription(); // Fetch table data

    if (!tableData || tableData.length === 0) {
      alert('No data available to export.');
      return;
    }

    // Extract plain text values from JSX elements
    const formattedData = tableData.map((row) => ({
      userName: row.userName.props.children, // Extract text inside JSX <div>
      email: row.email.props.children,
      planName: row.planName.props.children,
      subAddedOn: row.subAddedOn.props.children,
      status: row.status.props.children,
    }));

    // Convert to CSV format
    const headers = Object.keys(formattedData[0]).join(',');
    const rows = formattedData.map((row) => Object.values(row).join(',')).join('\n');

    const csvContent = `data:text/csv;charset=utf-8,${headers}\n${rows}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');

    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'users_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    RetailSubscription();
  }, [showEditDelete, isModalVisible]);
  const LastDaysFilter = [
    { value: 7, label: 'Last 7 days' },
    { value: 15, label: 'Last 15 days' },
    { value: 30, label: 'Last 30 days' },
    { value: 60, label: 'Last 60 days' },
    { value: 90, label: 'Last 90 days' },
  ];
  // const formatDate = (date) => {
  //   if (!date) return '';
  //   const day = date.date();
  //   const suffix =
  //     day % 10 === 1 && day !== 11
  //       ? 'st'
  //       : day % 10 === 2 && day !== 12
  //       ? 'nd'
  //       : day % 10 === 3 && day !== 13
  //       ? 'rd'
  //       : 'th';
  //   return `${day}${suffix} ${date.format('MMM, YYYY')}`;
  // };
  const getSubscriptionData = async () => {
    const response = await getSubscription();
    setSubscriptionPlans(response);
  };
  useEffect(() => {
    getSubscriptionData();
  }, []);
  return (
    <>
      {/* Add and Edit Modal for User Management */}
      <CommonModal
        width={600}
        visible={isModalVisible?.addModal}
        isModalVisible={() => {
          setIsModalVisible({ ...isModalVisible, addModal: false });
          setShowEditDelete({
            show: false,
            id: 0,
          });
        }}
      >
        <div className={style['modal_Container']}>
          <div className={style['modal_title']}>Add New User</div>
          <div className={style['modal_description']}>
            Tailor subscription plans for your corporate clients effortlessly
          </div>
          <div className={style['from-field']} style={{ marginTop: '36px' }}>
            <div className={style['name']}>Name</div>
            <div className={style['input-style']}>
              <Input placeholder="Enter Name" name="name" value={addNewUser.name} onChange={handleInputChange} />
            </div>
          </div>
          <div className={style['from-field']} style={{ marginTop: '16px' }}>
            <div className={style['name']}>Email Id</div>
            <div className={style['input-style']}>
              <Input placeholder="Enter Email Id" name="email" value={addNewUser.email} onChange={handleInputChange} />
            </div>
          </div>
          <div className={style['from-field']} style={{ marginTop: '16px' }}>
            <div className={style['name']}>Subscription Plan</div>
            <div className={style['input-style']}>
              <CustomDropDownForSelect
                placeholder="Select"
                options={{ array: subscriptionPlans, key: '_id' }}
                label={{ array: subscriptionPlans, key: 'name' }}
                defaultValueForSelectOption={addNewUser.subscriptionId}
                onChangeForSelect={handleDropdownChange}
                customStyle={{
                  height: '40px',
                  border: '1px solid #d2d2d2',
                }}
                hoverColor="#E6E6E6"
                dropdownStyle={{ zIndex: 1050 }}
              />
              {/* <CustomDropDownForSelect
                placeholder="Select"
                options={{ array: subscriptionPlans, key: 'subscriptionId' }}
                label={{ array: subscriptionPlans, key: 'name' }}
                customStyle={{
                  height: '40px',
                  border: '1px solid #d2d2d2',
                  marginTop: '16px',
                }}
                hoverColor="#E6E6E6"
                dropdownStyle={{ zIndex: 1050 }}
              /> */}
            </div>
          </div>
          <div className={style['btns-container']}>
            <button className={style['save-changes']} onClick={handleSaveChanges}>
              Save Changes
            </button>
          </div>
        </div>
      </CommonModal>
      {/* Delete Modal for User Management */}
      <CommonModal
        width={600}
        visible={isModalVisible?.deleteModal}
        isModalVisible={() => {
          setIsModalVisible({ ...isModalVisible, deleteModal: false });
          setShowEditDelete({
            show: false,
            id: 0,
          });
          setIsRevoked(false);
        }}
      >
        <div className={style['delete_modal_Container']}>
          <WarningIcon />
          <div className={style['delete_modal_title']}>
            Are you sure you want to {isRevoked ? 'access revoked' : 'delete'} this user?{' '}
          </div>
          <div className={style['delete_modal_description']}>
            {isRevoked ? 'Access Revoking' : 'Deleting'} this user will also remove their active subscriptions.
            <br /> {isRevoked ? 'You can reactivate this user.' : 'This action cannot be undone.'}
          </div>
          <button className={style['delete-btn']} onClick={handleDeleteUser}>
            <DeleteIcon />
            Yes, {isRevoked ? 'Access Revoked' : 'Delete User'}
          </button>
          <button
            className={style['goback-btn']}
            onClick={() => {
              setIsModalVisible({ ...isModalVisible, deleteModal: false });
              setIsRevoked(false);
            }}
          >
            Go back
          </button>
        </div>
      </CommonModal>
      {/* bulk user upload modal */}
      <CommonModal
        width={600}
        visible={bulkModal}
        isModalVisible={() => {
          setBulkModal(false);
          setSelectedFile(null);
          document.getElementById('fileInput').value = '';
        }}
        centered
      >
        <div className={style['modal_Container']}>
          <div className={style['modal_title']}>Bulk Upload</div>
          <div className={style['modal_description']}>
            Tailor subscription plans for your corporate clients effortlessly
          </div>
          <div className={style['from-field']} style={{ marginTop: '36px' }}>
            <div className={style['name']}>Upload CSV</div>
            <div className={style['imageUploader']}>
              <input type="file" accept=".csv" onChange={handleFileChange} id="fileInput" style={{ display: 'none' }} />

              {selectedFile ? (
                <div className={style['upload_Icon']} style={uploadContainerStyle}>
                  <span>{selectedFile.name}</span>
                  <CloseOutlined
                    onClick={handleRemoveFile}
                    style={{ marginLeft: 8, cursor: 'pointer', color: 'red' }}
                  />
                </div>
              ) : (
                <div style={uploadContainerStyle}>
                  <label
                    htmlFor="fileInput"
                    className={style['upload_Icon']}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      cursor: 'pointer',
                    }}
                  >
                    <UploadIcon style={{ color: '#595959', fontSize: '20px' }} />
                    <div style={{ textDecoration: 'underline', color: '#0353E9' }}>Click to Upload File</div>
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className={style['btns-container']} style={{ display: 'flex', gap: '30px' }}>
            <button
              style={{
                border: '1px solid #D9D9D9',
                padding: '12px 20px',
                backgroundColor: '#fff',
                borderRadius: '3px',
              }}
              onClick={handleModalRemoveFile}
            >
              Cancel
            </button>
            <button className={style['save-changes']} onClick={handleSaveChanges}>
              Add Users
            </button>
          </div>
        </div>
      </CommonModal>

      {/* bulk modal ended here */}

      <div className={style['main-container']}>
        <div className={style['header-logo']}>
          <img
            onClick={() => redirectToDomain(websiteDomain)}
            className={style['image-logo']}
            src={toggleData?.brandLogoUrl || Demo_Image}
            alt="Demo_Image"
          />
        </div>
        <div className={clsx(style['flex-Justify-between'], style['container'])}>
          <div className={style['header-title']}>User Management Dashboard</div>
          <div style={{ position: 'relative' }}>
            <div className={style['profile-container']} onClick={() => setLogout(!logout)}>
              <div className={style['profile-name']}>
                {licenseCount.name
                  .split(' ')
                  .map((word) => word[0])
                  .join('')
                  .toUpperCase()
                  .slice(0, 2)}
              </div>
              <img src={Arrow_icon} alt="Arrow_icon" style={{ rotate: logout ? '0deg' : '180deg' }} />
            </div>
            {logout && <div className={style['logout']}>Logout</div>}
          </div>
        </div>
        <div className={style['body-container']}>
          <div className={clsx(style['flex-Justify-between'], style['containe-2'])}>
            <div className={style['welcome-back']}>
              Welcome back, <span>{licenseCount.name}</span>
            </div>
            {/* <div>13 January, 2024 11:23AM</div> */}
            {/* <CurrentDateTime /> */}
          </div>
          <div className={clsx(style['flex-Justify-between'])} style={{ marginTop: '25px' }}>
            <div className={style['licenses-box']}>
              <div className={style['licenses-title']}>Total licenses</div>
              <div className={style['number-licenses']}>{licenseCount.totalLicenses}</div>
            </div>
            <div className={style['licenses-box']}>
              <div className={style['licenses-title']}>Licenses Used</div>
              <div className={style['number-licenses']}>{licenseCount.usedLicenses}</div>
            </div>
            <div className={style['licenses-box']}>
              <div className={style['licenses-title']}>Licenses Left</div>
              <div className={style['number-licenses']}>{licenseCount.licensesLeft}</div>
            </div>
          </div>
          <div className={clsx(style['flex-Justify-between'])} style={{ marginTop: '20px' }}>
            <div>
              <div className={style['search-box']}>
                <Input placeholder="Search By Name" value={searchQuery} onChange={handleSearch} />
                <SearchOutlined rev={undefined} />
              </div>
              <div></div>
            </div>
            <div className={style['flex-center']} style={{ gap: '10px' }}>
              <CustomDropDownForSelect
                placeholder="Select"
                options={{ array: LastDaysFilter, key: 'value' }}
                label={{ array: LastDaysFilter, key: 'label' }}
                customStyle={{
                  width: '135px',
                  height: '40px',
                  border: '1px solid #d2d2d2',
                  backgroundColor: '#F2F2F2',
                  marginLeft: '30px',
                }}
                onChange={(val) => handleDaysFilterChange(val)}
                hoverColor="#E6E6E6"
              />
              <div className={style['download-icon']} onClick={exportToCSV}>
                <DownloadIcon />
                <span>Export CSV</span>
              </div>
              <div className={style['range-container']}>
                <div className={style['date-picker-wrap']}>
                  <div className={style['divider']}>
                    <img src={calenderIcon} alt="calenderIcon" />
                  </div>
                  <DatePicker
                    placeholder="Start Date"
                    value={fromDate}
                    suffixIcon={null}
                    format="DD MMM YYYY"
                    disabledDate={(current) => current.isAfter(dayjs().endOf('day'))}
                    popupStyle={{ fontSize: '11px', zIndex: 2000 }}
                    onChange={(date) => handleDateChange({ from: date })}
                  />
                  <div className={style['divider']}>
                    <div className={style['arrow-img-wrapper']}>
                      <img src={lineSolid} alt="line-solid" />
                    </div>
                  </div>
                  <DatePicker
                    placeholder="End Date"
                    value={toDate}
                    suffixIcon={null}
                    format="DD MMM YYYY"
                    disabledDate={(current) =>
                      current.isAfter(dayjs().endOf('day')) || (fromDate && current.isBefore(fromDate))
                    }
                    popupStyle={{ fontSize: '11px', zIndex: 2000 }}
                    onChange={(date) => handleDateChange({ to: date })}
                  />
                  <div className={style['divider']}>
                    <div className={style['arrow-img-wrapper']}>
                      <img src={DropDownArrow} alt="arrow_down" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style['table-container']}>
            <div className={style['container-space-between']}>
              <div className={style['flex-center']} style={{ gap: '5px' }}>
                <div className={style['active-user-title']}>User Details</div>
                <Tooltip color="#1D2943" placement="right" title={<span style={{ fontSize: '12px' }}>TBD</span>}>
                  <InfoIcon />
                </Tooltip>
              </div>
              <div className={style['flex-center']} style={{ gap: '20px' }}>
                <div className={style['imageUploader']}>
                  <a href="/sample_users_Sheet.csv" download style={{textDecoration:'none', color:'#000'}}>
                    <div className={style['upload_Icon']}>
                      <DownloadIcon />
                      <div>Sample CSV</div>
                    </div>
                  </a>
                </div>
                <div className={style['imageUploader']} onClick={() => setBulkModal((prev) => !prev)}>
                  <div className={style['upload_Icon']}>
                    <UploadIcon />
                    <div>Bulk Upload Users</div>
                  </div>
                </div>
                <div
                  className={style['add-new-user']}
                  onClick={() => {
                    setIsModalVisible({ ...isModalVisible, addModal: true });
                    setAddNewUser({
                      name: '',
                      email: '',
                      subscriptionId: '',
                    });
                  }}
                >
                  <AddIcon />
                  <div>Add New User</div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '14px' }}>
              <div>
                {/* <ConfigProvider
                  theme={{
                    components: {
                      Table: {
                        headerSplitColor: 'transparent',
                      },
                    },
                  }}
                > */}
                <Table
                  dataSource={filteredData}
                  columns={TableColumns}
                  pagination={{
                    position: ['bottomRight'],
                    showSizeChanger: false,
                    pageSize: 10,
                  }}
                  // scroll={{ x: 'max-content' }}
                />
                {/* </ConfigProvider> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagement;
