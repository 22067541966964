import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import style from '../Style/Edituser.module.css';
import Arrow from './icons/back Vector';
import Male from './icons/male';
import Female from './icons/female';
import Others from './icons/others';
import Mobile from './icons/mobile_icon';
import Calender from './icons/calender';
import { CSCCheckJWTExpired, CSCGetAccessToken } from './utills';
import clsx from 'clsx';
import Button from './button';
import { Country_flag_json } from '../common/country-flag-json';
import { DatePicker, Input, Modal, Select } from 'antd';
import { ReactComponent as DropDownArrow } from '../assets/loginsettings/dropDownArrow.svg';
import { ReactComponent as Verified } from '../Style/verfied.svg';
import Search_icon from '../assets/loginsettings/search_icon.svg';
import loginStyle from '../NewLogin/review-screens.module.scss';
import axios from 'axios';
import { antdMessageError, antdMessageSuccess, antdMessageWarning } from '../../Components/common/antdMessageComponent';
import { checkSuccessCode, logOutHandlerForMobile, userProfileUpdateHandlerForMobile } from '../../utills/validator';

const Edit = ({ setscreen, rightvaluepassed, color }) => {
  const [data, setdata] = useState();
  const [secondaryEmail, setsecondaryEmail] = useState('');
  const [secondaryPhone, setSecondaryPhone] = useState('');
  const [click, setClick] = useState(true);
  const [defaultCountry, setDefaultCountry] = useState('+91')
  useEffect(() => {
    setname(data?.name || '');
    setemail(data?.email || '');
    setimobile(data?.phoneNumber || '');
    setSelectedGender(data?.gender || '');
    setDateOfBirth(
      data?.dateOfBirth && dayjs(data.dateOfBirth).isValid() && data.dateOfBirth !== 'NaN-NaN-NaN'
        ? dayjs(data.dateOfBirth)
        : '',
    );
    setcode(data?.countryCode || defaultCountry);
    setIsEmail(data?.isEmailVerified || false);
    setIsPhone(data?.isPhoneVerified || false);
    setsecondaryEmail(data?.secondaryEmail);
    setSecondaryPhone(data?.secondaryPhone);
  }, [data]);

  const textColor = '#00063d';
  const backgroundColor = '#f5f5ff';
  const highlightColor = '#7583ff';
  const logoutColor = '#949fff';
  const inputfieldColor = '#e0e4ff';
  const deleteColor = '#ff6666';
  const fontstyle = 'poppins';

  const collor = data?.userProfileCustomization?.highlightColor || highlightColor;
  const ClientId = JSON.parse(localStorage.getItem('commonUrlData'));

  const [name, setname] = useState('');
  const [email, setemail] = useState('');
  const [imobile, setimobile] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [inputValue, SetInputValue] = useState('');
  const [code, setcode] = useState(defaultCountry);
  const [invalid, setinvalid] = useState(false);
  const [isEmail, setIsEmail] = useState(true);
  const [isPhone, setIsPhone] = useState(true);
  const [dateOfBirth, setDateOfBirth] = useState();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const convertedOtp = +otp.join('')

  let reqData = {
    name: name.trim(),
    mobile: imobile,
    email: email,
    gender: selectedGender.trim(),
    dateOfBirth: dateOfBirth,
    countryCode: code,
    secondaryEmail: secondaryEmail,
    secondaryPhone: secondaryPhone,
  };

  let cleanedReqData = Object.fromEntries(
    Object.entries(reqData).filter(([key, value]) => value !== undefined && value !== '' && value !== 'NaN-NaN-NaN'),
  );

  const updateuserdetails = async () => {
    setClick(false);
    const userCode = localStorage.getItem('accessToken');
    // if (
    // (cleanedReqData?.phone &&
    //   cleanedReqData?.phone !== '' &&
    //   !parsePhoneNumberFromString(cleanedReqData?.countryCode + cleanedReqData?.phone)?.isValid()) ||
    // (cleanedReqData?.secondaryPhone &&
    //   cleanedReqData?.secondaryPhone !== '' &&
    //   !parsePhoneNumberFromString(cleanedReqData?.countryCode + cleanedReqData?.secondaryPhone)?.isValid())
    // ) {
    // setClick(true)
    // return antdMessageWarning('Please enter a valid number.');
    // }
    if (userCode !== null) {
      try {
        const response = await axios({
          method: 'PUT',
          url: `${process.env.REACT_APP_API_URL}/authorization/edit-user-details`,
          headers: { Authorization: `Bearer ${userCode}` },
          data: {
            ...cleanedReqData,
          },
        });
        antdMessageSuccess('Updated Successfully');
        userProfileUpdateHandlerForMobile(JSON.stringify({ message: 'USER_PROFILE_UPDATE', statusCode: '200' }));
        setscreen('userdetails');
        setClick(true);
      } catch (error) {
        setClick(true);
        antdMessageError(
          error?.response?.data?.message ||
          error?.response?.data?.error ||
          error?.response?.data?.errors?.phoneNumber ||
          error?.response?.data?.errors?.email ||
          'Error While updating user data',
        );
      }
    } else {
      setClick(true);
      antdMessageError('It seems your account has been logged out, Please try logging in again.');
      logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
      window.parent.postMessage({ source: 'userDetailAction' }, '*');
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    setIsFocused3(false);
  };
  const handleBlur = () => setIsFocused(false);
  const handleFocus1 = () => {
    setIsFocused1(true);
    setIsFocused3(false);
  };
  const handleBlur1 = () => setIsFocused1(false);
  const handleFocus2 = () => {
    setIsFocused2(true);
    setIsFocused3(false);
  };
  const handleBlur2 = () => setIsFocused2(false);
  const handleFocus3 = () => setIsFocused3(true);
  const handleBlur3 = () => setIsFocused3(false);

  const [isFocused, setIsFocused] = useState(false);
  const [isFocused1, setIsFocused1] = useState(false);
  const [isFocused2, setIsFocused2] = useState(false);
  const [isFocused3, setIsFocused3] = useState(false);

  const divStyle = {
    background: data?.userProfileCustomization?.inputFieldColor || inputfieldColor,
    border: isFocused ? `1px solid ${collor}` : '',
    outline: 'none ',
    boxShadow: isFocused ? '0 0 0' : '0 0 2px',
  };
  const divStyle1 = {
    background: data?.userProfileCustomization?.inputFieldColor || inputfieldColor,
    border: isFocused1 ? `1px solid ${collor}` : '',
    outline: 'none ',
    boxShadow: isFocused1 ? '0 0 0' : '0 0 2px',
  };
  const divStyle2 = {
    background: data?.userProfileCustomization?.inputFieldColor || inputfieldColor,
    border: isFocused2 ? `1px solid ${collor}` : '',
    outline: 'none ',
    boxShadow: isFocused2 ? '0 0 0' : '0 0 2px',
  };
  const divStyle3 = {
    borderRadius: '5px',
    background: data?.userProfileCustomization?.inputFieldColor || inputfieldColor,
    border: isFocused3 ? `1px solid ${collor}` : '',
    outline: 'none ',
    boxShadow: isFocused3 ? '0 0 0' : '0 0 2px',
  };

  const handleGenderClick = (gender) => {
    setSelectedGender(gender);
  };

  const [okay, setokay] = useState(false);

  useEffect(() => {
    if (name.trim().length >= 1 && invalid) {
      setokay(true);
    } else {
      setokay(false);
    }
  }, [name, invalid]);

  useEffect(() => {
    if (data?.isEmailVerified) {
      var e = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
      setinvalid(e);
    } else {
      setinvalid(true);
    }
  }, [email]);

  const filteredOptions = Country_flag_json.filter(
    (option) =>
      option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.dail_code.toLowerCase().includes(inputValue.toLowerCase()),
  );

  const dropdownRender = (menu) => (
    <div>
      <div className={loginStyle['dropDown-search']}>
        <Input placeholder="Search..." onChange={(e) => SetInputValue(e.target.value)} />
        <img src={Search_icon} alt="Search_icon" />
      </div>
      {menu}
    </div>
  );

  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);
  const clientId = queryParams.get('clientId');

  const fetchData = async (token) => {
    if (token) {
      try {
        const response = await axios({
          method: 'GET',
          url: `${process.env.REACT_APP_API_URL}/authorization/get-loggedin-user-details`,
          headers: { Authorization: `Bearer ${token}` },
        });
        setdata(response.data);
        setLoading(false);
      } catch (error) {
        if (error?.response?.status === 404) {
          antdMessageError('It seems your account has been logged out. Please try logging in again');
          window.parent.postMessage({ source: 'logout-from-user' }, '*');
          logOutHandlerForMobile(JSON.stringify({ message: 'LOGOUT_SUCCESS' }));
        }
      }
    }
  };
  const verify = () => {
    getOtp();
    setIsModalOpen(true);
  }
  useEffect(() => {
    const refreshToken = queryParams.get('refreshToken');

    refreshToken &&
      (async () => {
        const accessToken = localStorage.getItem('accessToken');
        CSCCheckJWTExpired(accessToken);
        if (accessToken.length > 10 && !CSCCheckJWTExpired(accessToken)) {
          fetchData(accessToken);
        } else {
          const accessToken = await CSCGetAccessToken(refreshToken);
          localStorage.setItem('accessToken', accessToken);
          fetchData(accessToken);
        }
      })();
  }, []);

  useEffect(() => {
    (async () => {
      const detectCountry = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/auth/detect-country`,
      });
      if (checkSuccessCode(detectCountry)) {
        const value = Country_flag_json?.find((e) => e?.code === detectCountry?.data?.countryCode);
        setDefaultCountry(value?.dail_code);
      }
    })();
  }, []);
  useEffect(() => {
    if (data?.secondaryPhone) {
      if (secondaryPhone.length === 0) {
        antdMessageError('Phone number cannot be blank. Please enter a valid phone number to proceed.');
        setClick(false);
      } else {
        setClick(true);
      }
    }
  }, [secondaryPhone]);

  const [timeLeft, setTimeLeft] = useState(60);
  const formatTime = () => {
    const minutes = String(Math.floor(timeLeft / 60)).padStart(2, "0");
    const seconds = String(timeLeft % 60).padStart(2, "0");
    return `${minutes} : ${seconds}`;
  };

  const getOtp = async () => {
    try {
      setTimeLeft(60);
      const Token = localStorage.getItem('accessToken');
      const requestData = {
        clientId: ClientId.clientId,
        phone : secondaryPhone,
        countryCode : code,
        email:secondaryEmail,
      };
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/merge/send-otp`,
        headers: { Authorization: `Bearer ${Token}` },
        data: requestData,
      })
      if (response.status === 201) {
        antdMessageSuccess(response.data.message)
      }
    } catch (error) {
      antdMessageError(error.response.data.message);
      console.log(error);
    }
  };
  const verifyOtp = async () => {
    try {
      const Token = localStorage.getItem('accessToken');
      const requestData = {
        clientId: ClientId.clientId,
        phone : secondaryPhone,
        countryCode : code,
        email:secondaryEmail,
        code: convertedOtp,
      };
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/merge/verify-otp`,
        headers: { Authorization: `Bearer ${Token}` },
        data: requestData
      });
      if (response.status === 201) {
        antdMessageSuccess(response.data.message)
        setIsModalOpen(false);
        setOtp(["", "", "", ""]);
        fetchData(Token);
      }
    } catch (error) {
      antdMessageError(error.response.data.message);
      console.log(error);
      setIsModalOpen(false);
      setOtp(["", "", "", ""]);
    }
  };

  const [rightvalue, setrightvalue] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (data?.userProfileCustomization?.drawerPosition === 'RIGHT') {
      setrightvalue(0);
    } else {
      setrightvalue();
    }
  }, [data, rightvalue]);
  useEffect(() => { }, [loading]);
  const none = () => { };


  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (index < otp.length - 1 && value !== '') {
      document.getElementById(`digit${index + 2}`).focus();
    }
    setEnableSubmitButton(isOtpComplete(newOtp));
  };

  const [enableSubmitButton, setEnableSubmitButton] = useState(false);
  const isOtpComplete = (otp) => {
    return otp.every((value) => value !== '');
  };
  console.log(enableSubmitButton)
  const handleBackspace = (index, event, digit) => {
    if (event.key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
      if (index === 3 && digit !== '') {
        document.getElementById(`digit${index + 1}`).focus();
      } else if (index > 0) {
        document.getElementById(`digit${index}`).focus();
      }
      setEnableSubmitButton(isOtpComplete(newOtp));
    } else if (event.key === 'Enter') {
    }
  };

  useEffect(() => {
    if (timeLeft <= 0) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  return (
    <>
      <Modal open={isModalOpen} maskClosable={false} onOk={() => setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)} footer={null} width={400}>
        <div style={{ display: 'flex', gap: '50px', flexDirection: 'column', padding: '10px' }}>
          <div>
            <div style={{ fontSize: '24px', fontWeight: '600', textAlign: 'center' }}>Verify with OTP</div>
            <p style={{ fontSize: '16px', fontWeight: '400', textAlign: 'center', color: '#7B7B7B' }}>A Verification code has been sent to {!data?.isEmailVerified ? email : secondaryPhone}</p>
          </div>
          <div style={{ display: 'flex', gap: '20px', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="tel"
                  id={`digit${index + 1}`}
                  value={digit}
                  maxLength={1}
                  className={loginStyle['inputField']}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 1 && /^[0-9]?$/.test(value)) {
                      handleChange(index, value);
                    }
                  }}
                  onKeyDown={(e) => handleBackspace(index, e, digit)}
                  style={{ width: '19%', fontSize: '30px' }}
                />
              ))}
            </div>
            <div style={{ textAlign: 'center' }}><span style={{ fontSize: '14px', fontWeight: 500, textDecoration: 'underline', opacity: timeLeft === 0 ? 1 : 0.5, cursor: timeLeft === 0 ? "pointer" : 'not-allowed' }} onClick={() => timeLeft === 0 ? getOtp() : ''}> Resend OTP in</span>&nbsp; {formatTime()} sec</div>
          </div>
          <div style={{ height: '44px', width: '100%', background: '#000000', color: '#ffffff', fontWeight: 500, borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '16px', opacity: enableSubmitButton ? 1 : .6, cursor: enableSubmitButton ? "pointer" : 'not-allowed' }} onClick={() => enableSubmitButton && verifyOtp()}>Verify</div>
        </div>
      </Modal>
      {loading ? (
        <div
          className={style['outercontainer']}
          style={{
            background: color,
            right: rightvaluepassed,
          }}
        >
          <div className={style['loader-container']}>
            <div className={style['loader']}></div>
          </div>
        </div>
      ) : (
        <div
          className={style['outercontainer']}
          style={{
            right: rightvalue,
            background: data?.userProfileCustomization?.backgroundColor || backgroundColor,
            fontFamily: data?.userProfileCustomization?.fontStyle || fontstyle,
            color: data?.userProfileCustomization?.primaryTextColor || textColor,
          }}
        >
          <div className={style['editable']}>
            <div
              className={style['head']}
              style={{ borderBottom: `.8px solid ${data?.userProfileCustomization?.primaryTextColor || textColor}` }}
            >
              <div className={style['arrow']} onClick={() => setscreen('userdetails')}>
                <Arrow fill={data?.userProfileCustomization?.primaryTextColor || textColor} />
              </div>
              <div className={style['topic']}>Edit Your Profile</div>
            </div>
            <div className={style['content']}>
              <div className={style['namecont']}>
                <span
                  className={style['headtag']}
                  style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor }}
                >
                  Name*
                </span>
                <div className={style['inputbtns']} style={divStyle}>
                  <input
                    className={style['inputtag']}
                    placeholder="Enter your Name"
                    type="text"
                    value={name}
                    style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      const reg = /^[A-Za-z0-9 ]+$/;
                      const value = e.target.value;
                      if (reg.test(value) || value === '') {
                        setname(value);
                      }
                    }}
                  />
                </div>
              </div>

              <div className={style['cont']} >
                <span
                  className={style['headtag']}
                  style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor, opacity: data?.isEmailVerified ? '.6' : '' }}
                >
                  E-mail
                </span>
                <div className={style['inputbtns']} style={{ ...divStyle1, width: !data?.isEmailVerified ? '75%' : '100%', }}>
                  <input
                    required
                    disabled={data?.isEmailVerified}
                    className={style['inputtag']}
                    placeholder="Enter your email"
                    type="email"
                    value={email || secondaryEmail}
                    style={{
                      color: data?.userProfileCustomization?.primaryTextColor || textColor,
                      cursor: data?.isEmailVerified && 'not-allowed',
                      opacity: data?.isEmailVerified ? '.6' : ''
                    }}
                    onFocus={handleFocus1}
                    onBlur={handleBlur1}
                    onChange={(e) => (email ? setemail(e.target.value) : setsecondaryEmail(e.target.value))}
                  />
                  {!data?.isEmailVerified &&
                    <div style={{ background: data?.userProfileCustomization?.primaryTextColor || textColor, fontWeight: 500, color: data?.userProfileCustomization?.backgroundColor || backgroundColor, padding: '2px 15px', borderRadius: '5px', position: 'absolute', right: '17px', height: '44px', display: 'flex', alignItems: 'center' }} onClick={() => verify()}>
                      Verify
                    </div>}
                  {data?.isEmailVerified && <Verified />}
                </div>
                {!invalid ? (
                  <span className={style['emp']}>
                    <small>Please input the valid email</small>
                  </span>
                ) : (
                  ''
                )}
              </div>

              <div className={style['cont']} style={{ opacity: data?.isPhoneVerified && 0.6 }}>
                <span
                  className={style['headtag']}
                  style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor }}
                >
                  Mobile
                </span>
              </div>

              <div className={`${style['inputbtns']} ${style['mobilecont']}`} style={{ ...divStyle2, width: !data?.isPhoneVerified ? '75%' : '100%' }}>
                <Mobile fill={collor} />
                <div className={`${loginStyle['dropDown-container']} ${style['drop1']}`} style={{ color: 'red' }}>
                  <Select
                    defaultValue={data?.countryCode || code}
                    onFocus={handleFocus2}
                    onBlur={handleBlur2}
                    dropdownRender={dropdownRender}
                    popupMatchSelectWidth={250}
                    filterOption={false}
                    disabled={data?.isPhoneVerified}
                    suffixIcon={<DropDownArrow />}
                    className={style['common-select-box']}
                    popupClassName={style['common-dropdown-select']}
                    optionLabelProp="label"
                    style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor }}
                    onChange={(e) => setcode(e)}
                    placeholder="+00"
                  >
                    {filteredOptions.map((option, index) => (
                      <Select.Option
                        key={index}
                        value={option.dail_code}
                        label={option.dail_code}
                        className={style['dropdown-options']}
                      >
                        <div className={loginStyle['dropdown-flag']}>
                          <div className={loginStyle['flag-code']} style={{ fontSize: '12px' }}>
                            {option.dail_code}
                          </div>
                          <div className={loginStyle['flag-name']}>{option.name}</div>
                          <div className={loginStyle['flag']}>{option.flag}</div>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className={style['vertical-line']} style={{ borderRight: `1.5px solid ${collor}` }}></div>
                <input
                  required
                  className={style['inputtag']}
                  placeholder="Enter your Phone number"
                  type="tel"
                  style={{
                    color: data?.userProfileCustomization?.primaryTextColor || textColor,
                    opacity: data?.isPhoneVerified && 0.6,
                    cursor: data?.isPhoneVerified && 'not-allowed',
                  }}
                  value={imobile || secondaryPhone}
                  minLength="15"
                  maxLength="15"
                  disabled={data?.isPhoneVerified}
                  onFocus={handleFocus2}
                  onBlur={handleBlur2}
                  onChange={(e) => {
                    const reg = /^$|^\d{0,15}$/;
                    const value = e.target.value;
                    if (reg.test(value)) {
                      imobile ? setimobile(value) : setSecondaryPhone(value);
                    }
                  }}
                />
                {!data?.isPhoneVerified &&
                  <div style={{ background: data?.userProfileCustomization?.primaryTextColor || textColor, fontWeight: 500, color: data?.userProfileCustomization?.backgroundColor || backgroundColor, padding: '2px 15px', borderRadius: '5px', position: 'absolute', right: '17px', height: '44px', display: 'flex', alignItems: 'center' }} onClick={() => verify()}>
                    Verify
                  </div>}
                {data?.isPhoneVerified && <Verified style={{position:'absolute',right:'30px',height:'17px'}}/>}
              </div>

              <div className={style['cont']}>
                <span
                  className={style['headtag']}
                  style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor }}
                >
                  Date Of Birth
                </span>
                <div className={style['dinputtag']} style={divStyle3} onFocus={handleFocus3}>
                  <DatePicker
                    style={{
                      width: '100%',
                      background: data?.userProfileCustomization?.inputFieldColor || inputfieldColor,
                      color: data?.userProfileCustomization?.primaryTextColor || textColor,
                      height: '44px',
                      border: 'none',
                    }}
                    allowClear={false}
                    suffixIcon={<Calender fill={collor} />}
                    placeholder="Enter your Date of Birth"
                    inputReadOnly={true}
                    onChange={(e) => {
                      if (e) {
                        const utcDate = new Date(
                          Date.UTC(e.year(), e.month(), e.date(), e.hour(), e.minute(), e.second(), e.millisecond()),
                        ).toISOString();
                        setDateOfBirth(utcDate);
                        setIsFocused3(false);
                      }
                    }}
                    format="DD-MM-YYYY"
                    value={dateOfBirth ? dayjs(dateOfBirth, 'YYYY-MM-DDTHH:mm:ss.sssZ') : ''}
                    disabledDate={(current) => current.isAfter(dayjs().endOf('day'))}
                  />
                </div>
              </div>
              {(clientId && clientId != '673454977d7bef5f987dfaf2' && clientId != '651e8fa1d2b7386e55403ac9') && (
                <div className={style['cont']}>
                  <span
                    className={style['headtag']}
                    style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor }}
                  >
                    Select Your Gender
                  </span>
                  <div className={style['all']}>
                    <div
                      className={clsx(style['gender'], { [style['active']]: selectedGender === 'MALE' })}
                      style={{
                        background: data?.userProfileCustomization?.inputFieldColor || inputfieldColor,
                        border: selectedGender === 'MALE' && `1px solid ${collor}`,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleGenderClick('MALE')}
                    >
                      <span>
                        <Male fill={collor} />
                      </span>
                      <span style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor }}>Male</span>
                    </div>
                    <div
                      className={clsx(style['gender'], { [style['active']]: selectedGender === 'FEMALE' })}
                      style={{
                        background: data?.userProfileCustomization?.inputFieldColor || inputfieldColor,
                        border: selectedGender === 'FEMALE' && `1px solid ${collor}`,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleGenderClick('FEMALE')}
                    >
                      <span>
                        <Female fill={collor} />
                      </span>
                      <span style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor }}>
                        Female
                      </span>
                    </div>
                    <div
                      className={clsx(style['gender'], { [style['active']]: selectedGender === 'OTHERS' })}
                      style={{
                        background: data?.userProfileCustomization?.inputFieldColor || inputfieldColor,
                        border: selectedGender === 'OTHERS' && `1px solid ${collor}`,
                        cursor: 'pointer',
                      }}
                      onClick={() => handleGenderClick('OTHERS')}
                    >
                      <span>
                        <Others fill={collor} />
                      </span>
                      <span style={{ color: data?.userProfileCustomization?.primaryTextColor || textColor }}>
                        Others
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className={style['cont']} style={{ textAlign: 'right', padding: '10px 0 10px 0' }}>
                <button onClick={() => setscreen('delete')} style={{ cursor: 'pointer', background: 'none' }}>
                  <span
                    className={style['headtag']}
                    style={{
                      color: data?.userProfileCustomization?.primaryTextColor || textColor,
                      textDecoration: 'underline',
                    }}
                  >
                    Delete Account
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className={`${style['buttoncontainer']} ${!okay || !click ? style['blur'] : ''}`}>
            <Button
              color={data?.userProfileCustomization?.highlightColor || highlightColor}
              fnc={okay && invalid && click ? updateuserdetails : none}
              text="Update Profile"
              font={data?.userProfileCustomization?.fontStyle || fontstyle}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Edit;
